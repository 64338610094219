import React from 'react';
import {Grid, Typography} from '@mui/material';

import field from '../../resources/us/main.jpeg';

// External
import {Parallax} from 'react-parallax';

const styles = {
  parallaxContainer: {
    width: '100%',
    height: '25rem',
  },
  parallax: {
    width: '100%',
    height: '100%',
  },
  titleContainer: {
    marginTop: '6rem',
    marginLeft: '4rem',
  },
  subtitle: {
    marginTop: '2rem',
  },
};

export function EntryImage() {
  return (
    <Grid justifyContent="center" container>
      <Grid item xs={12} sm={12} sx={styles.parallaxContainer}>
        <Parallax
          blur={{min: -15, max: 15}}
          bgImage={field}
          bgImageAlt="field"
          strength={-200}
          style={styles.parallax}>
          <Grid
            container
            justifyContent="flex-start"
            sx={styles.titleContainer}>
            <Grid item xs={12} sm={5}>
              <Typography variant="h2" color="secondary">
                Noticias
              </Typography>
              <Typography variant="h6" color="secondary" sx={styles.subtitle}>
                Mantente al día con las últimas noticias de Agro del Sur
              </Typography>
            </Grid>
          </Grid>
        </Parallax>
      </Grid>
    </Grid>
  );
}

export default EntryImage;
