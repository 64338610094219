import React from 'react';
import {useNavigate} from 'react-router-dom';

import {
  Grid,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from '@mui/material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';

export function Services() {
  const navigate = useNavigate();
  return (
    <Grid container spacing={4} justifyContent="center">
      <Grid item xs={12} sm={3}>
        <Card sx={{width: '100%', height: '100%'}} elevation={3}>
          <CardActionArea
            sx={{width: '100%', height: '100%'}}
            onClick={() => {
              navigate('/services', {state: {section: '0'}});
            }}>
            <CardContent>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
                sx={{height: '100%'}}>
                <Grid item xs={12} sx={{textAlign: 'center'}}>
                  <DescriptionOutlinedIcon
                    color="primary"
                    sx={{width: '100%', height: '8vh'}}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    align="center"
                    color="text.secondary">
                    Evaluación de plaguicidas
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>

      <Grid item xs={12} sm={3}>
        <Card sx={{width: '100%', height: '100%'}} elevation={3}>
          <CardActionArea
            sx={{width: '100%', height: '100%'}}
            onClick={() => {
              navigate('/services', {state: {section: '1'}});
            }}>
            <CardContent>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
                sx={{height: '100%'}}>
                <Grid item xs={12} sx={{textAlign: 'center'}}>
                  <ScienceOutlinedIcon
                    color="primary"
                    sx={{width: '100%', height: '8vh'}}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    align="center"
                    color="text.secondary">
                    Evaluación de variedades
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>

      <Grid item xs={12} sm={3}>
        <Card sx={{width: '100%', height: '100%'}} elevation={3}>
          <CardActionArea
            sx={{width: '100%', height: '100%'}}
            onClick={() => {
              navigate('/services', {state: {section: '2'}});
            }}>
            <CardContent>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
                sx={{height: '100%'}}>
                <Grid item xs={12} sx={{textAlign: 'center'}}>
                  <PublicOutlinedIcon
                    color="primary"
                    sx={{width: '100%', height: '8vh'}}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    align="center"
                    color="text.secondary">
                    Transferencia tecnológica
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>

      <Grid item xs={12} sm={3}>
        <Card sx={{width: '100%', height: '100%'}} elevation={3}>
          <CardActionArea
            sx={{width: '100%', height: '100%'}}
            onClick={() => {
              navigate('/services', {state: {section: '3'}});
            }}>
            <CardContent>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
                sx={{height: '100%'}}>
                <Grid item xs={12} sx={{textAlign: 'center'}}>
                  <SchoolOutlinedIcon
                    color="primary"
                    sx={{width: '100%', height: '8vh'}}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    align="center"
                    color="text.secondary">
                    Capacitación
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Services;
