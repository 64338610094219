import plaguicidas1 from '../../resources/services/plaguicidas/plaguicidas_10.JPG';
import plaguicidas2 from '../../resources/services/plaguicidas/plaguicidas_11.JPG';
import plaguicidas3 from '../../resources/services/plaguicidas/plaguicidas_12.JPG';
import plaguicidas4 from '../../resources/services/plaguicidas/plaguicidas_13.JPG';
import plaguicidas5 from '../../resources/services/plaguicidas/plaguicidas_14.JPG';
import plaguicidas6 from '../../resources/services/plaguicidas/plaguicidas_15.JPG';

import variedades2 from '../../resources/services/variedades/variedades_5.JPG';
import variedades3 from '../../resources/services/variedades/variedades_6.JPG';
import variedades7 from '../../resources/services/variedades/variedades_7.JPG';
import variedades8 from '../../resources/services/variedades/variedades_8.JPG';

import transferencia1 from '../../resources/services/transferencia/transferencia_4.jpg';
import transferencia2 from '../../resources/services/transferencia/transferencia_5.jpg';
import transferencia7 from '../../resources/services/transferencia/transferencia_7.JPG';

import capacitacion2 from '../../resources/services/capacitacion/capacitacion_4.JPG';
import capacitacion5 from '../../resources/services/capacitacion/capacitacion_5.JPG';
import capacitacion6 from '../../resources/services/capacitacion/capacitacion_6.jpg';

export default function sectionMapping() {
  return [
    {
      title: 'Evaluación de eficacia de plaguicidas',
      description: [
        'La Estación Experimental Agro del Sur se especializa en la evaluación de eficacia de los diversos agroquímicos, en proceso de registro o comerciales, sobre las principales enfermedades, malezas e insectos de importancia económica en el sur de Chile. Para esto cuenta con un equipo de investigadores de amplia y reconocida experiencia en cada una de sus disciplinas quienes, apoyados por personal profesional, técnico y operarios especializados en trabajo de campo, se abocan individual y responsablemente al proceso de evaluación y posterior emisión de los informes técnicos correspondientes.',
      ],
      specialText: [
        'En la ejecución de estas actividades especializadas, se otorgan todas aquellas condiciones de infección, infestación o ambientales posibles, requeridas para asegurar la adecuada evaluación de los diferentes agroquímicos en evaluación.',
      ],
      // images: [
      //   plaguicidas1,
      //   plaguicidas2,
      //   plaguicidas3,
      //   plaguicidas4,
      //   plaguicidas5,
      //   plaguicidas6,
      // ],
      images: [
        {
          img: plaguicidas1,
          title: 'plaguicidas1',
          rows: 2,
          cols: 2,
        },
        {
          img: plaguicidas2,
          title: 'plaguicidas2',
        },
        {
          img: plaguicidas3,
          title: 'plaguicidas3',
        },
        {
          img: plaguicidas5,
          title: 'plaguicidas5',
          cols: 2,
        },
        {
          img: plaguicidas4,
          title: 'plaguicidas4',
          rows: 2,
          cols: 2,
        },
        {
          img: plaguicidas6,
          title: 'plaguicidas6',
          rows: 2,
          cols: 2,
        },
      ],
    },
    {
      title: 'Evaluación de variedades',
      description: [
        'La Estación Experimental Agro del Sur ejecuta evaluaciones de variedades comerciales de diversos cultivos, frente a las principales enfermedades que los afectan en la zona sur de Chile. Se especializa en otorgar condiciones para la adecuada expresión de las diversas enfermedades, de tal forma de evaluar el comportamiento de las variedades frente a los patógenos de importancia económica.',
      ],
      specialText: [
        'También evalúa la sensibilidad/tolerancia de las variedades frente a diversos plaguicidas y productos agrícolas; adaptación, productividad y parámetros de calidad.',
      ],
      // images: [variedades1, variedades2, variedades3],
      images: [
        {
          img: variedades3,
          title: 'variedades3',
          rows: 2,
          cols: 2,
        },
        {
          img: variedades2,
          title: 'variedades2',
          rows: 2,
          cols: 2,
        },
        {
          img: variedades8,
          title: 'variedades8',
          rows: 2,
          cols: 2,
        },
        {
          img: variedades7,
          title: 'variedades7',
          rows: 2,
          cols: 2,
        },
      ],
    },
    {
      title: 'Transferencia tecnológica',
      description: [
        'La Estación Experimental Agro del Sur realiza transferencia de tecnología a través de actividades de días de campo en sus propias instalaciones, visitando y explicando los trabajos relacionados con la identificación, prevención y control de enfermedades en cultivos, así como de malezas y de insectos de importancia agrícola y forestal, al igual que de prácticas agronómicas enfocadas en aspectos fitosanitarios y productivos. También realiza transferencia tecnológica a través de reuniones grupales o asesorías a agricultores, técnicos y profesionales del área agrícola y empresas relacionadas, en auditóriums o campo, atendiendo temáticas específicas de interés del o los solicitantes.',
      ],
      specialText: [
        'Tanto el investigador permanente como los investigadores asociados, poseen amplia y reconocida experiencia en sus respectivos campos profesionales, así como una larga trayectoria en docencia universitaria, en cursos de pre y posgrado.',
        'La transferencia tecnológica que realiza Agro del Sur, se materializa en su propio sitio web a través de la página "Aprende con nosotros", donde se describe con gran detalle y excelente material audiovisual, las principales enfermedades que afectan a los cultivos en la zona sur de Chile, su identificación, prevención y control. Dicho material está destinado a generar autogestión en el manejo de las enfermedades, tanto de agricultores, asesores, técnicos y profesionales del agro, como de estudiantes y profesores del área agrícola.',
      ],
      images: [
        {
          img: transferencia1,
          title: 'transferencia1',
          rows: 2,
          cols: 2,
        },
        {
          img: transferencia2,
          title: 'transferencia2',
          rows: 2,
          cols: 2,
        },
        {
          img: transferencia7,
          title: 'transferencia7',
          rows: 2,
          cols: 2,
        },
      ],
    },
    {
      title: 'Capacitación',
      description: [
        'La Estación Experimental Agro del Sur realiza capacitaciones a agricultores, asesores, técnicos y profesionales del área agrícola y empresas relacionadas, al igual que de estudiantes y profesores de liceos agrícolas, institutos profesionales y universidades. Estas capacitaciones están relacionadas con microorganismos patógenos, malezas e insectos de importancia agrícola y forestal; la identificación, prevención y control de enfermedades, malezas y plagas insectiles de importancia agrícola y también forestal; manejo y uso de plaguicidas; prácticas agronómicas enfocadas en aspectos fitosanitarios y productivos.',
      ],
      specialText: [
        'Tanto el investigador principal como los investigadores asociados, poseen amplia y reconocida experiencia en sus respectivos campos profesionales, así como una larga trayectoria en docencia universitaria, en cursos de pre y posgrado. Todos son poseedores de un rico material audiovisual que complementa en excelente forma la transmisión de los conocimientos.',
      ],
      images: [
        {
          img: capacitacion2,
          title: 'capacitacion2',
          rows: 2,
          cols: 2,
        },
        {
          img: capacitacion5,
          title: 'capacitacion5',
          rows: 2,
          cols: 2,
        },
        {
          img: capacitacion6,
          title: 'capacitacion6',
          rows: 2,
          cols: 2,
        },
      ],
    },
  ];
}
