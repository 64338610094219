import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Grid, Button, Typography} from '@mui/material';

// Custom images
import AgrodelSurBackground from '../../../resources/test2.png';

export function AboutUs() {
  const navigate = useNavigate();
  return (
    <Grid
      justifyContent="center"
      container
      sx={{
        backgroundImage: `url(${AgrodelSurBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '200%',
        width: '100%',
      }}>
      <Grid item xs={12} sm={12} sx={{marginTop: '4rem'}}>
        <Typography align="center" variant="h3" color="secondary">
          Innovación e investigación
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8} sx={{marginTop: '2rem'}}>
        <Typography align="center" variant="h6" color="secondary">
          La Estación Experimental Agro del Sur contribuye al desarrollo de la
          agricultura nacional y mundial, a través de actividades de
          investigación y desarrollo, enfocadas principalmente en los aspectos
          fitosanitarios, enfermedades, malezas e insectos, que afectan a los
          cultivos.
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        sx={{textAlign: 'center', marginBottom: '2rem', marginTop: '2rem'}}>
        <Button
          variant="outlined"
          color="secondary"
          size={'large'}
          onClick={() => {
            navigate('/us');
          }}>
          Nosotros
        </Button>
      </Grid>
    </Grid>
  );
}

export default AboutUs;
