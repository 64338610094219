import React, {useEffect} from 'react';
import {Grid} from '@mui/material';

// External
import EntryImage from './EntryImage';
import NewsList from './NewsList';

export function News() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid justifyContent="center" container>
      <Grid item xs={12} sm={12}>
        <EntryImage />
      </Grid>
      <Grid item xs={12} sm={12}>
        <NewsList />
      </Grid>
    </Grid>
  );
}

export default News;
