import * as React from 'react';
import {
  Box,
  Grid,
  Tab,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
  Divider,
  useMediaQuery,
} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const sectionMapping = (moreInfo, section) => {
  switch (section) {
    case 'Diagnóstico':
      return moreInfo['diagnosis'];
    case 'Control':
      return moreInfo['control'];
    case 'Prevención':
      return moreInfo['prevention'];
    default:
      return moreInfo['diagnosis'];
  }
};

export default function LearningTextDialog(props) {
  const {handleClose, open, title, moreInfo} = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const [value, setValue] = React.useState('Diagnóstico');

  const currentInformation = sectionMapping(moreInfo, value);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const textFormatting = item => {
    return item.texts.map((text, textIndex) => {
      if (text.type === 'title') {
        return (
          <Typography
            variant="h4"
            color="primary"
            gutterBottom
            key={textIndex}
            sx={{paddingTop: '1rem'}}>
            {text.value}
          </Typography>
        );
      } else if (text.type === 'subtitle') {
        return (
          <Typography
            variant="body1"
            color="text.secondary"
            gutterBottom
            key={textIndex}>
            {text.value}
          </Typography>
        );
      } else if (text.type === 'body') {
        return text.value.map((bodyText, bodyIndex) => {
          return (
            <Typography
              variant="body1"
              color="text.secondary"
              gutterBottom
              key={bodyIndex}
              sx={{paddingTop: '1rem'}}>
              {bodyText}
            </Typography>
          );
        });
      } else {
        return null;
      }
    });
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent style={{height: '60rem'}}>
        <Grid justifyContent="center" container>
          <Grid item xs={12} sm={12}>
            <TabContext value={value}>
              <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <TabList
                  onChange={handleChange}
                  variant={matches ? 'scrollable' : 'fullWidth'}
                  scrollButtons={matches}
                  allowScrollButtonsMobile
                  aria-label="lab API tabs example">
                  <Tab label="Diagnóstico" value="Diagnóstico" />
                  <Tab label="Control" value="Control" />
                  <Tab label="Prevención" value="Prevención" />
                </TabList>
              </Box>
            </TabContext>
          </Grid>
        </Grid>
        <Grid justifyContent="center" container sx={{marginTop: '2rem'}}>
          {currentInformation.map((item, index) => {
            const isOdd = (index + 1) % 2 !== 0;
            if (item.type === 'main') {
              return (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{textAlign: 'center'}}
                  key={index}>
                  <Grid
                    container
                    spacing={0}
                    alignItems="center"
                    alignContent="center"
                    justifyContent="center">
                    <Grid item xs={12} sm={6}>
                      <Typography
                        variant="h4"
                        color="text.secondary"
                        gutterBottom>
                        {item.title}
                      </Typography>
                      <Typography variant="h3" color="primary" gutterBottom>
                        {item.causeTitle}
                      </Typography>
                      <Typography
                        variant="h5"
                        color="text.secondary"
                        gutterBottom>
                        {item.subtitle}
                      </Typography>
                      <Typography variant="h6" color="primary" gutterBottom>
                        {item.causeSubtitle}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{height: '100%'}}>
                      <img
                        src={item.image}
                        style={{
                          height: '100%',
                          width: 'auto',
                          maxWidth: '100%',
                          //marginTop: '2rem',
                        }}
                        alt=""
                      />
                    </Grid>
                    <Divider
                      variant="fullWidth"
                      sx={{
                        width: '100%',
                        marginTop: '2rem',
                        textAlign: 'center',
                      }}
                    />
                  </Grid>
                </Grid>
              );
            } else {
              return (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{minHeight: '10vh', textAlign: 'center'}}
                  key={index}>
                  <Grid
                    container
                    spacing={0}
                    alignItems="center"
                    alignContent="center"
                    justifyContent="center">
                    <Grid item xs={12} sm={1} />
                    {item.image ? (
                      <React.Fragment>
                        <Grid
                          item
                          xs={12}
                          sm={isOdd ? 4 : 5}
                          sx={{textAlign: 'center'}}>
                          {isOdd ? (
                            <React.Fragment>
                              {textFormatting(item)}
                            </React.Fragment>
                          ) : (
                            <img
                              src={item.image}
                              style={{
                                height: '100%',
                                width: 'auto',
                                maxWidth: '100%',
                                marginTop: '2rem',
                              }}
                              alt=""
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} sm={1} />
                        <Grid item xs={12} sm={isOdd ? 5 : 4}>
                          {isOdd ? (
                            <img
                              src={item.image}
                              style={{
                                height: '100%',
                                width: 'auto',
                                maxWidth: '100%',
                                marginTop: '2rem',
                              }}
                              alt=""
                            />
                          ) : (
                            <React.Fragment>
                              {textFormatting(item)}
                            </React.Fragment>
                          )}
                        </Grid>
                      </React.Fragment>
                    ) : (
                      <Grid item xs={12} sm={8}>
                        {textFormatting(item)}
                      </Grid>
                    )}
                    <Grid item xs={12} sm={1} />
                    {index + 1 < currentInformation.length ? (
                      <Divider
                        variant="fullWidth"
                        sx={{
                          width: '100%',
                          marginTop: '2rem',
                          textAlign: 'center',
                        }}
                      />
                    ) : null}
                  </Grid>
                </Grid>
              );
            }
          })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
}
