import * as React from 'react';
import {useLocation} from 'react-router-dom';
import {Grid, Tab, Box, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import useMediaQuery from '@mui/material/useMediaQuery';
// Icons
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';

// Carousel
import 'react-multi-carousel/lib/styles.css';

// Mapping
import sectionMapping from './SectionMapping';

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

const mapSections = () => {
  const sections = sectionMapping();
  return sections.map((item, index) => {
    return (
      <TabPanel value={`${index}`} key={index}>
        <Grid
          container
          sx={{paddingTop: '1rem', minHeight: '30rem'}}
          justifyContent={'center'}
          justifyItems={'center'}>
          <Grid item xs={12} sm={5} sx={{paddingTop: '1.5rem'}}>
            <Grid
              container
              sx={{height: '80%'}}
              justifyContent={'center'}
              alignItems={'center'}>
              <Grid item xs={12} sm={12}>
                <Typography variant="h3" align="center" color="primary">
                  {item.title}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} sx={{paddingTop: '4vw'}}>
                {item.description.map((item, index) => {
                  return (
                    <Typography
                      key={index}
                      variant="body1"
                      align="center"
                      gutterBottom>
                      {item}
                    </Typography>
                  );
                })}
              </Grid>
              <Grid item xs={12} sm={12}>
                {item.specialText.map((item, index) => {
                  return (
                    <Typography
                      key={index}
                      variant="body1"
                      align="center"
                      gutterBottom>
                      {item}
                    </Typography>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={1} />
          <Grid item xs={12} sm={4} sx={{paddingTop: '1.5rem'}}>
            <ImageList
              //sx={{width: 500, height: 100%}}
              variant="quilted"
              cols={4}
              rowHeight={121}>
              {item.images.map(item => (
                <ImageListItem
                  key={item.img}
                  cols={item.cols || 1}
                  rows={item.rows || 1}>
                  <img
                    {...srcset(item.img, 121, item.rows, item.cols)}
                    alt={item.title}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Grid>
          {/*<Grid item xs={10} sm={10} xl={10} sx={{padding: '3rem'}}>*/}
          {/*  <Paper*/}
          {/*    variant="outlined"*/}
          {/*    sx={{*/}
          {/*      width: '100%',*/}
          {/*      height: '100%',*/}
          {/*      borderLeftColor: 'primary.main',*/}
          {/*      backgroundColor: 'grey.50',*/}
          {/*      borderLeftWidth: 4,*/}
          {/*      borderRightWidth: -4,*/}
          {/*    }}>*/}
          {/*    <Grid container spacing={2} sx={{padding: '2rem'}}>*/}
          {/*      {item.specialText.map((item, index) => (*/}
          {/*        <Grid*/}
          {/*          item*/}
          {/*          xs={12}*/}
          {/*          sm={12}*/}
          {/*          sx={{marginTop: '1rem'}}*/}
          {/*          key={index}>*/}
          {/*          <Typography variant="body1" align="left">*/}
          {/*            {item}*/}
          {/*          </Typography>*/}
          {/*        </Grid>*/}
          {/*      ))}*/}
          {/*    </Grid>*/}
          {/*  </Paper>*/}
          {/*</Grid>*/}
        </Grid>
      </TabPanel>
    );
  });
};

export default function ServiceTabs(props) {
  let location = useLocation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const [value, setValue] = React.useState('0');

  React.useEffect(() => {
    // Google Analytics
    if (location.state?.section) {
      setValue(location.state.section);
    }
  }, [location]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <TabContext value={value}>
      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
        <TabList
          onChange={handleChange}
          variant={matches ? 'scrollable' : 'fullWidth'}
          scrollButtons={matches}
          allowScrollButtonsMobile
          aria-label="lab API tabs example">
          <Tab
            icon={<DescriptionOutlinedIcon />}
            label="Evaluación de plaguicidas"
            value="0"
            sx={{fontSize: 'medium'}}
          />
          <Tab
            icon={<ScienceOutlinedIcon />}
            label="Evaluación de variedades"
            value="1"
            sx={{fontSize: 'medium'}}
          />
          <Tab
            icon={<PublicOutlinedIcon />}
            label="Transferencia Tecnológica"
            value="2"
            sx={{fontSize: 'medium'}}
          />
          <Tab
            icon={<SchoolOutlinedIcon />}
            label="Capacitación"
            value="3"
            sx={{fontSize: 'medium'}}
          />
        </TabList>
      </Box>
      {mapSections()}
    </TabContext>
  );
}
