import React from 'react';
import {Grid} from '@mui/material';

// Sections
import EntryImage from './EntryImage';
import ServiceTabs from './ServiceTabs';

export function Services() {
  return (
    <Grid justifyContent="center" container>
      <Grid item xs={12} sm={12}>
        <EntryImage />
      </Grid>
      <Grid item xs={12} sm={12}>
        <ServiceTabs />
      </Grid>
    </Grid>
  );
}

export default Services;
