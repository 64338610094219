import React from 'react';
import {Divider, Grid, Typography} from '@mui/material';

// Images
import wheat from '../../resources/us/wheat.jpeg';
import AgrodelSurBackground from '../../resources/test2.png';

const styles = {
  mainBody: {
    marginTop: '1rem',
  },
  values: {
    marginTop: '3rem',
  },
};

export function Values() {
  return (
    <React.Fragment>
      <Grid container justifyContent="center">
        <Grid item xs={10} sm={5}>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Typography variant="h4" color="primary">
                Lo que nos mueve
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12} sm={12} sx={styles.mainBody}>
              <Typography variant="body1" align={'justify'} gutterBottom>
                La Estación Experimental Agro del Sur se ubica en el km 1 del
                camino Ruta 5 Sur (entrada sur) a Perquenco, Comuna de
                Perquenco, Región de La Araucanía.
              </Typography>
              <Typography variant="body1" align={'justify'} gutterBottom>
                Fue creada el año 2013 y cuenta con resolución del Servicio
                Agrícola y Ganadero desde Abril de 2014. Sus principales
                actividades están relacionadas con el desarrollo de
                agroquímicos, a través de la evaluación de eficacia de
                fungicidas, herbicidas e insecticidas. Además, realiza
                investigación en estrategias de control de enfermedades,
                evaluación de variedades comerciales y líneas promisorias de
                diversos cultivos frente a las principales enfermedades de la
                zona sur, evaluación de fertilizantes, bioestimulantes y agentes
                de control biológico, entre otras. Todas las actividades son
                ejecutadas exclusivamente por especialistas de larga y
                reconocida trayectoria en investigación y en las diversas
                disciplinas agronómicas.
              </Typography>
              <Typography variant="body1" align={'justify'}>
                La Estación Experimental Agro del Sur provee igualmente
                capacitación y transferencia tecnológica a agricultores,
                asesores, profesionales y empresas del agro.
              </Typography>
              <Typography variant="body1" align={'justify'} gutterBottom>
                A través de su sitio web especializado en enfermedades, entrega
                en forma gratuita antecedentes para el diagnóstico, prevención y
                control de las principales enfermedades que afectan a los
                cultivos en la zona sur de Chile.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={10} sm={5} sx={{height: '100%', textAlign: 'center'}}>
          <img
            src={wheat}
            alt="wheat"
            style={{width: 'auto', height: '100%', maxHeight: '25rem'}}
          />
        </Grid>

        <Grid item xs={10} sm={10} sx={styles.values}>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} sm={6}>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h4" color="primary">
                    Misión
                  </Typography>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={12} sx={styles.mainBody}>
                  <Typography variant="body1" align={'justify'} gutterBottom>
                    La Estación Experimental Agro del Sur contribuye al
                    desarrollo de la agricultura nacional y mundial, a través de
                    actividades de investigación y desarrollo, enfocadas
                    principalmente en los aspectos fitosanitarios, enfermedades,
                    malezas e insectos, que afectan a los cultivos.
                  </Typography>
                  <Typography variant="body1" align={'justify'} gutterBottom>
                    Por medio de servicios de investigación y desarrollo
                    prestados a las empresas de agroquímicos, de insumos
                    agrícolas, comerciales y productivas, aportamos con nuestra
                    actividad profesional, responsable, comprometida y
                    transparente, al mejoramiento de las prácticas agronómicas y
                    del medio ambiente, proporcionando la información
                    técnico-científica que incremente los indicadores de
                    eficiencia y mejor uso de plaguicidas, el empleo de las
                    mejores variedades comerciales desde el punto de vista
                    sanitario, la prevención y control oportuno de enfermedades,
                    malezas e insectos.
                  </Typography>
                  <Typography variant="body1" align={'justify'} gutterBottom>
                    Contribuimos a la formación de técnicos y profesionales del
                    agro, a través de capacitación y transferencia tecnológica
                    directa y también, a través de la información especializada
                    proporcionada ampliamente en nuestro sitio web
                    especializado.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h4" color="primary">
                    Visión
                  </Typography>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={12} sx={styles.mainBody}>
                  <Typography variant="body1" align={'justify'} gutterBottom>
                    Ser reconocidos nacional e internacionalmente, como una
                    Estación Experimental de investigación agrícola
                    especializada, comprometida con las metas y objetivos de sus
                    clientes, por su significativo aporte al desarrollo de la
                    agricultura nacional y mundial y, por su responsabilidad en
                    la ejecución de cada una de sus actividades, realizadas y
                    supervisadas por un equipo de investigadores de amplia y
                    reconocida experiencia en sus especialidades.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          justifyContent="center"
          container
          sx={{
            backgroundImage: `url(${AgrodelSurBackground})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '200%',
            width: '100%',
            marginTop: '3rem',
          }}>
          <Grid
            item
            xs={12}
            sm={12}
            sx={{marginTop: '2rem', marginBottom: '1rem'}}>
            <Typography align="center" variant="h4" color="secondary">
              Valores
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8} sx={{marginBottom: '2rem'}}>
            <Typography align="center" variant="body1" color="secondary">
              Los valores en que se basa la actividad de la Estación
              Experimental Agro del Sur, son el compromiso y la responsabilidad
              en todas nuestras actividades de investigación y desarrollo, en la
              relación con nuestros clientes y, en la normativa que rige
              nuestras actividades técnico-científicas; la transparencia de
              nuestro quehacer; el esfuerzo permanente por perfeccionar nuestras
              actividades y la ética irrestricta aplicada en nuestros informes
              técnico-científicos y en el manejo de la información confidencial
              de nuestros clientes.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Values;
