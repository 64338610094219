import React from 'react';
import {Grid, Typography} from '@mui/material';

import field from '../../resources/learning/main.JPG';

// External
import {Parallax} from 'react-parallax';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const makeStyles = matches => ({
  parallaxContainer: {
    width: '100%',
    height: '50vh',
  },
  parallax: {
    width: '100%',
    height: '100%',
  },
  titleContainer: {
    marginTop: '15vh',
    marginLeft: '4rem',
  },
  subtitle: {
    marginTop: '2rem',
    textAlign: 'left',
  },
  bgImageMatches: {
    top: '25%',
  },
  bgImageBig: {
    top: '-35%',
  },
});

export function EntryImage() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const styles = makeStyles(matches);
  return (
    <Grid justifyContent="center" container>
      <Grid item xs={12} sm={12} sx={styles.parallaxContainer}>
        <Parallax
          blur={{min: -15, max: 15}}
          bgImage={field}
          bgImageAlt="field"
          strength={-200}
          style={styles.parallax}
          bgImageStyle={matches ? styles.bgImageMatches : styles.bgImageBig}>
          <Grid
            container
            justifyContent="flex-start"
            sx={styles.titleContainer}>
            <Grid item xs={8} sm={5}>
              <Typography
                variant="h2"
                color="secondary"
                sx={{textAlign: 'left'}}>
                Aprende con nosotros
              </Typography>
              <Typography variant="h6" color="secondary" sx={styles.subtitle}>
                Enfermedades de cultivos zona sur
              </Typography>
            </Grid>
          </Grid>
        </Parallax>
      </Grid>
    </Grid>
  );
}

export default EntryImage;
