import pie_negro from '../../../resources/learning/raps/pie_negro.jpeg';
import pie_negroPDF from '../../../resources/learning/raps/pie_negro.pdf';
import pudricion_blanca from '../../../resources/learning/raps/pudricion_blanca.jpeg';
import pudricion_blancaPDF from '../../../resources/learning/raps/pudricion_blanca.pdf';

// Control - Pie negro
import control_pie_image_1 from '../../../resources/learning/raps/pie_negro/control/image_1.jpeg';
import control_pie_image_2 from '../../../resources/learning/raps/pie_negro/control/image_2.jpeg';
import control_pie_image_3 from '../../../resources/learning/raps/pie_negro/control/image_3.jpeg';
import control_pie_image_4 from '../../../resources/learning/raps/pie_negro/control/image_4.jpeg';
import control_pie_image_5 from '../../../resources/learning/raps/pie_negro/control/image_5.jpeg';
import control_pie_image_6 from '../../../resources/learning/raps/pie_negro/control/image_6.jpeg';
import control_pie_image_7 from '../../../resources/learning/raps/pie_negro/control/image_7.jpeg';

// Diagnosis - Pie negro
import diagnosis_pie_main from '../../../resources/learning/raps/pie_negro/diagnosis/main.jpeg';
import diagnosis_pie_image_1 from '../../../resources/learning/raps/pie_negro/diagnosis/image_1.jpeg';
import diagnosis_pie_image_2 from '../../../resources/learning/raps/pie_negro/diagnosis/image_2.jpeg';
import diagnosis_pie_image_3 from '../../../resources/learning/raps/pie_negro/diagnosis/image_3.jpeg';
import diagnosis_pie_image_4 from '../../../resources/learning/raps/pie_negro/diagnosis/image_4.jpeg';
import diagnosis_pie_image_5 from '../../../resources/learning/raps/pie_negro/diagnosis/image_5.jpeg';
import diagnosis_pie_image_6 from '../../../resources/learning/raps/pie_negro/diagnosis/image_6.jpeg';

// Prevention - Pie negro
import prevention_pie_image_1 from '../../../resources/learning/raps/pie_negro/prevention/image_1.jpeg';
import prevention_pie_image_2 from '../../../resources/learning/raps/pie_negro/prevention/image_2.jpeg';
import prevention_pie_image_3 from '../../../resources/learning/raps/pie_negro/prevention/image_3.jpeg';
import prevention_pie_image_4 from '../../../resources/learning/raps/pie_negro/prevention/image_4.jpeg';

// Control - Pudrición blanca
import control_blanca_image_1 from '../../../resources/learning/raps/pudricion_blanca/control/image_1.jpeg';
import control_blanca_image_2 from '../../../resources/learning/raps/pudricion_blanca/control/image_2.jpeg';
import control_blanca_image_3 from '../../../resources/learning/raps/pudricion_blanca/control/image_3.jpeg';
import control_blanca_image_4 from '../../../resources/learning/raps/pudricion_blanca/control/image_4.jpeg';
import control_blanca_image_5 from '../../../resources/learning/raps/pudricion_blanca/control/image_5.jpeg';
import control_blanca_image_6 from '../../../resources/learning/raps/pudricion_blanca/control/image_6.jpeg';

// Diagnosis - Pudrición blanca
import diagnosis_blanca_main from '../../../resources/learning/raps/pudricion_blanca/diagnosis/main.jpeg';
import diagnosis_blanca_image_1 from '../../../resources/learning/raps/pudricion_blanca/diagnosis/image_1.jpeg';
import diagnosis_blanca_image_2 from '../../../resources/learning/raps/pudricion_blanca/diagnosis/image_2.jpeg';
import diagnosis_blanca_image_3 from '../../../resources/learning/raps/pudricion_blanca/diagnosis/image_3.jpeg';
import diagnosis_blanca_image_4 from '../../../resources/learning/raps/pudricion_blanca/diagnosis/image_4.jpeg';
import diagnosis_blanca_image_5 from '../../../resources/learning/raps/pudricion_blanca/diagnosis/image_5.jpeg';
import diagnosis_blanca_image_6 from '../../../resources/learning/raps/pudricion_blanca/diagnosis/image_6.jpeg';
import diagnosis_blanca_image_7 from '../../../resources/learning/raps/pudricion_blanca/diagnosis/image_7.jpeg';

// Prevention - Pie negro
import prevention_blanca_image_1 from '../../../resources/learning/raps/pudricion_blanca/prevention/image_1.jpeg';
import prevention_blanca_image_2 from '../../../resources/learning/raps/pudricion_blanca/prevention/image_2.jpeg';
import prevention_blanca_image_3 from '../../../resources/learning/raps/pudricion_blanca/prevention/image_3.jpeg';
import prevention_blanca_image_4 from '../../../resources/learning/raps/pudricion_blanca/prevention/image_4.jpeg';
import prevention_blanca_image_5 from '../../../resources/learning/raps/pudricion_blanca/prevention/image_5.jpeg';

const raps = {
  title: 'Raps',
  cards: [
    {
      title: 'Pie negro',
      description:
        'El pié negro del raps canola, o “Foma”, es una de las dos enfermedades más importantes en este cultivo, en la zona centro sur y sur del país.',
      dialogTitle: 'Pie negro del Raps',
      imageCard: pie_negro,
      pdf: pie_negroPDF,
      video:
        'https://www.youtube.com/watch?v=XYUG8zg5ZI4&ab_channel=AgrodelSurEstaci%C3%B3nExperimental',
      moreInfo: {
        control: [
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Desinfección de semilla',
              },
              {
                type: 'body',
                value: [
                  'Emplear un buen desinfectante de semillas contribuye a disminuir el nivel de infección inicial de "foma".',
                ],
              },
            ],
            image: control_pie_image_1,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Aplicación de fungicida al surco de siembra',
              },
              {
                type: 'body',
                value: [
                  'El uso de flutriafol (Impact) peletizado en super fosfato triple, en dosis de 2 lt/ha, ha demostrado disminuir significativamente el ataque de "foma" en raps, similar al logrado por tratamientos foliares.',
                ],
              },
            ],
            image: control_pie_image_2,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Aplicación foliar de fungicida',
              },
              {
                type: 'body',
                value: [
                  'El empleo oportuno de un buen fungicida foliar disminuye o controla efectivamente la infección de "foma".',
                ],
              },
            ],
            image: control_pie_image_3,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: '¿Con qué fungicidas se puede controlar el "Foma"?',
              },
              {
                type: 'body',
                value: [
                  'Consulte con su asesor profesional por el fungicida más adecuado.',
                  'Exija siempre antecedentes de eficacia del fungicida recomendado, principalmente de indicadores o notas de infección, obtenidos por profesionales calificados.',
                  'Eventualmente, deje un pequeño sector del cultivo (lejos de arboledas o bordes de caminos) sin tratar, para verificar la eficacia del producto recomendado.',
                ],
              },
            ],
            image: null,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: '¿Cuándo realizar la aplicación foliar"?',
              },
            ],
            image: control_pie_image_4,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Aplicación foliar en el momento oportuno',
              },
              {
                type: 'body',
                value: [
                  'La aplicación foliar de fungicida para controlar el "foma" del raps debe realizarse al estado de 2-3 hojas como máximo.',
                  'Tratamientos posteriores resultarán menos eficaces.',
                  'La razón es que el hongo infecta el cuello en estados tempranos de desarrollo (1-3 hojas).',
                  'La presencia de manchas de "foma" en las hojas después del estado de roseta no son importantes, por lo que no requieren tratamiento.',
                ],
              },
            ],
            image: control_pie_image_5,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Aplicación foliar sólo en el momento oportuno',
              },
              {
                type: 'body',
                value: [
                  'La presencia de manchas de "foma" en las hojas después del estado de roseta no son importantes, por lo que no requieren tratamiento.',
                ],
              },
            ],
            image: control_pie_image_6,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: '¡No malgaste su dinero!',
              },
            ],
            image: control_pie_image_7,
          },
        ],
        diagnosis: [
          {
            type: 'main',
            title: 'Organismo causal: ',
            causeTitle: 'Hongo',
            subtitle: 'Nombre del hongo: ',
            causeSubtitle: 'Leptosphaeria maculans; Anam.: Phoma lingam',
            image: diagnosis_pie_main,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Síntomas foliares',
              },
              {
                type: 'body',
                value: [
                  'Lesiones (manchas) en las hojas en estados de desarrollo temprano de las plantas (Imagen 1).',
                  'Abundantes lesiones en las hojas en estados más avanzados (Imagen 2)',
                ],
              },
            ],
            image: diagnosis_pie_image_1,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value:
                  'CLAVE : La clave importante en el diagnóstico es la presencia de puntos negros en el centro de las lesiones',
              },
              {
                type: 'body',
                value: [
                  'Lesión o mancha en una hoja de raps con el desarrollo de picnidios. (Imagen 1).',
                  'Los puntos negros corresponden a Picnidios, que son estructuras reproductivas asexuales del hongo que causa la "foma" (Imagen 2).',
                ],
              },
            ],
            image: diagnosis_pie_image_2,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value:
                  'CLAVE: Si no hay puntos negros en el centro de las manchas, entonces esas manchas no se deben a "foma"',
              },
              {
                type: 'body',
                value: [
                  'En estados más avanzados de desarrollo de las plantas, las manchas o lesiones de "foma" de las hojas se abren y desprenden, dejando orificios en las hojas (flecha). Los picnidios o puntos negros siguen visibles en estas lesiones.',
                ],
              },
            ],
            image: diagnosis_pie_image_3,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Caída de Plantas',
              },
              {
                type: 'body',
                value: [
                  'La enfermedad produce pudrición en el cuello, lo que provoca el corte y otras veces la tendedura de las plantas.',
                  'Las plantas tendidas tienden a levantarse desde la parte sana del tallo, adoptando formas de "L".',
                ],
              },
            ],
            image: diagnosis_pie_image_4,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Plantas secas',
              },
              {
                type: 'body',
                value: [
                  'La enfermedad produce pudrición del cuello, lo que provoca la aparición de plantas secas tempranamente.',
                ],
              },
            ],
            image: diagnosis_pie_image_5,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Pudrición del Cuello',
              },
              {
                type: 'body',
                value: [
                  'La pudrición temprana del cuello, provoca la aparición de plantas secas tempranamente.',
                  'Al cortar los cuellos de esas plantas hacia la cosecha, éstos aparecen con una pudrición seca (flechas).',
                ],
              },
            ],
            image: diagnosis_pie_image_6,
          },
        ],
        prevention: [
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Variedades resistentes',
              },
              {
                type: 'body',
                value: [
                  'En la actualidad (2010), hay escasa información sobre el nivel de resistencia de las variedades comerciales, en Chile.',
                  'La variedad de primavera Gladiator, ha manifestado un buen grado de resistencia al pié negro.',
                ],
              },
            ],
            image: prevention_pie_image_1,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Rotación de cultivos',
              },
              {
                type: 'body',
                value: [
                  'Idealmente no sembrar raps en un mismo potrero, antes de 3 años.',
                ],
              },
            ],
            image: prevention_pie_image_2,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Manejo de rastrojos',
              },
              {
                type: 'body',
                value: [
                  'La destrucción del rastrojo o facilitar su descomposición permite disminuir en forma importante la cantidad de hongo en los potreros.',
                  'El picado e incorporación profunda del rastrojo de raps es una buena práctica.',
                ],
              },
            ],
            image: prevention_pie_image_3,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Época de siembra',
              },
              {
                type: 'body',
                value: [
                  'Las siembras de primavera son mucho menos afectadas por la enfermedad, en comparación a las siembras de otoño e invierno.',
                ],
              },
            ],
            image: prevention_pie_image_4,
          },
        ],
      },
    },
    {
      title: 'Pudrición blanca',
      description:
        'Una enfermedad recurrente y de importancia económica en la zona productora de raps canola en Chile, es la pudrición blanca...',
      dialogTitle: 'Pudrición blanca del Raps',
      imageCard: pudricion_blanca,
      pdf: pudricion_blancaPDF,
      video:
        'https://www.youtube.com/watch?v=5IKxhixdvdI&ab_channel=AgrodelSurEstaci%C3%B3nExperimental',
      moreInfo: {
        control: [
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Variedades resistentes',
              },
              {
                type: 'body',
                value: [
                  'No existen variedades resistentes a la pudrición blanca.',
                ],
              },
            ],
            image: control_blanca_image_1,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Rotación de cultivos',
              },
              {
                type: 'body',
                value: [
                  'La rotación de cultivos ayuda en forma importante a disminuir la severidad de la infección.',
                  'En zonas con historial de pudrición blanca debe considerar a lo menos 3 años sin raps. No debe incluirse cultivos susceptibles en la rotación (ej.: lupino, trébol, alfalfa, maravilla).',
                  'Es importante de todas formas observar que la infección puede provenir de otros potreros o predios cercanos, puesto que las esporas del hongo de diseminan por el aire.\n',
                ],
              },
            ],
            image: control_blanca_image_2,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Control de malezas',
              },
              {
                type: 'body',
                value: [
                  'Realice un buen control de malezas, puesto que muchas de ellas, especialmente crucíferas y leguminosas, pueden mantener el hongo causal en el potrero.',
                ],
              },
            ],
            image: control_blanca_image_3,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Manejo de rastrojos',
              },
              {
                type: 'body',
                value: [
                  'El rastrojo debe ser incorporado en forma profunda o destruido. El rastrojo es la primera fuente de infección, puesto que ahí sobrevive el hongo.',
                  'La siembra de raps sobre rastrojo del mismo cultivo, se traducirá en una infección de mayor incidencia y severidad.',
                ],
              },
            ],
            image: control_blanca_image_4,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Aplicar fungicidas en el momento oportuno',
              },
              {
                type: 'body',
                value: [
                  'La pudrición blanca debe ser controlada tempranamente en la floración.',
                  'La aplicación de fungicida debe realizarse cuando el cultivo está con aproximadamente un 12 a 15% de floración (la fotografía muestra plantas con un 12% de floración, esto es con un promedio de 12 flores en el ramillete principal).',
                  'En temporadas predisponentes (alta pluviometría los 8-10 días previos al inicio de la floración y luego durante la floración) y, en variedades o híbridos de larga floración, se puede requerir un segundo tratamiento a los 25-30 días posteriores.',
                  'Recuerde que la aplicación foliar de fungicidas tiene su momento oportuno. El realizarlo en estados de desarrollo inadecuados, o con productos de baja eficacia, sólo se traducirá en un mal resultado económico.',
                ],
              },
            ],
            image: control_blanca_image_5,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Control',
              },
            ],
            image: control_blanca_image_6,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value:
                  '¿Con qué fungicidas se puede controlar la pudrición blanca?',
              },
              {
                type: 'body',
                value: [
                  'A la fecha hay escasos antecedentes en Chile sobre la eficacia de los fungicidas existentes en el mercado, en el control de la pudrición blanca del raps.',
                  'Consulte con su asesor profesional por el fungicida más adecuado.',
                  'Exija siempre antecedentes de eficacia del fungicida recomendado, principalmente de indicadores o notas de infección, obtenidos por profesionales calificados.',
                  'Eventualmente, deje un pequeño sector del cultivo (lejos de arboledas o bordes de caminos) sin tratar, para verificar la eficacia del producto recomendado.',
                ],
              },
            ],
            image: null,
          },
        ],
        diagnosis: [
          {
            type: 'main',
            title: 'Organismo causal: ',
            causeTitle: 'Hongo',
            subtitle: 'Nombre del hongo: ',
            causeSubtitle: 'Sclerotinia sclerotiorum',
            image: diagnosis_blanca_main,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'Los síntomas se detectan hacia fines de la floración (Imagen 1).',
                  'El receptáculo que se forma en la zona de inserción de las ramas y hojas, es donde se inician normalmente los síntomas. Ahí se acumulan pétalos infectados con el hongo y el agua de lluvia o del rocío (Imagen 2).',
                ],
              },
            ],
            image: diagnosis_blanca_image_1,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'La alta humedad y los nutrientes de los pétalos, activan al hongo iniciando el ataque y la pudrición del tallo (Image 1).',
                  'A los pocos días ya se puede observar el avance de la pudrición con el característico micelio blanquecino sobre el tejido afectado (Image 2).',
                ],
              },
            ],
            image: diagnosis_blanca_image_2,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'A medida que el ataque avanza, la pudrición comienza a circundar todo el contorno del tallo. Éste se torna blando y fácilmente quebradizo (Image 1).',
                  'En condiciones de alta humedad, el hongo desarrolla abundante micelio blanquecino sobre el tejido afectado e inicia la formación de esclerocios (estructuras de sobrevivencia) (Image 2).',
                ],
              },
            ],
            image: diagnosis_blanca_image_3,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'También se forman esclerocios en el interior de los tallos infectados (flechas), los cuales finalmente caerán al suelo durante la cosecha, manteniéndose ahí por varias Temporadas, dando inicio así a nuevas infecciones en los próximos cultivos de raps.',
                ],
              },
            ],
            image: diagnosis_blanca_image_4,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'Las plantas afectadas se secan prematuramente, apreciándose como si fuera un temprano proceso de madurez sectorizado o en manchones de plantas.',
                ],
              },
            ],
            image: diagnosis_blanca_image_5,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Ejemplo',
              },
            ],
            image: diagnosis_blanca_image_6,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'En temporadas con condiciones predisponentes , la pudrición blanca puede afectar a una alta población de plantas con un significativo impacto en el rendimiento y calidad del grano.',
                ],
              },
            ],
            image: diagnosis_blanca_image_7,
          },
        ],
        prevention: [
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'No hay variedades resistentes a la pudrición blanca. Se afectan por igual variedades o híbridos invernales y primaverales.',
                  'La prevención de la enfermedad se basa en prácticas agronómicas y en el uso de fungicidas.',
                ],
              },
            ],
            image: prevention_blanca_image_1,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Rotación de cultivos',
              },
              {
                type: 'subtitle',
                value:
                  'La rotación de cultivos ayuda a disminuir la severidad de la infección.',
              },
              {
                type: 'body',
                value: [
                  'No debe incluirse cultivos susceptibles en la rotación.',
                  'La rotación de cultivos no evita la infección, dado que ésta se inicia por esporas del hongo que provienen desde otros cultivos cercanos, por vía aérea. Pero, contribuye a disminuir el nivel de ataque.',
                ],
              },
            ],
            image: prevention_blanca_image_2,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Manejo de rastrojos',
              },
              {
                type: 'body',
                value: [
                  'El rastrojo debe ser incorporado o destruido.',
                  'El rastrojo es la primera fuente de infección, puesto que ahí sobrevive el hongo.',
                  'La siembra de raps sobre rastrojo del mismo cultivo, se traducirá en una infección de mayor incidencia y severidad.',
                ],
              },
            ],
            image: prevention_blanca_image_3,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Fertilización nitrogenada',
              },
              {
                type: 'title',
                value: 'No exceder la fertilización nitrogenada',
              },
              {
                type: 'body',
                value: [
                  'El exceso de nitrógeno retarda la lignificación y madurez de los tejidos, dejándolos más susceptibles al ataque por un tiempo más prolongado.',
                  'Además, favorece una mayor masa vegetativa lo que disminuye la aireación en la siembra, aumenta la humedad relativa y predispone a un ataque más severo.',
                ],
              },
            ],
            image: prevention_blanca_image_4,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Aplicar fungicidas en el momento oprtuno',
              },
              {
                type: 'body',
                value: [
                  'La aplicación foliar de fungicidas tiene su momento oportuno para cada enfermedad.',
                  'En el caso de la pudrición blanca, éste es entre un 15 y 25% de floración (12 a 16 flores en el ramillete principal).',
                  'Realizarlo en estados de desarrollo inadecuados, o con productos de baja eficacia, sólo se traducirá en un mal resultado económico.',
                  'Emplee sólo fungicidas reconocidamente eficaces. Elija aquellos que posean antecedentes de indicadores de infección (% daño foliar, % plantas afectadas, notas de severidad, etc.). Estos indicadores son mucho más importantes que los de rendimiento o calidad de grano.',
                ],
              },
            ],
            image: prevention_blanca_image_5,
          },
        ],
      },
    },
  ],
};

export default raps;
