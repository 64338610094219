import React from 'react';
import {Grid, Typography} from '@mui/material';

import field from '../../resources/services/main2.JPG';

// External
import {Parallax} from 'react-parallax';

const styles = {
  parallaxContainer: {
    width: '100%',
    height: '50vh',
  },
  parallax: {
    width: '100%',
    height: '100%',
  },
  titleContainer: {
    marginTop: '15vh',
    marginLeft: '4rem',
  },
  subtitle: {
    marginTop: '2rem',
    textAlign: 'left',
  },
  bgImage: {
    top: '-5%',
  },
};

export function EntryImage() {
  return (
    <Grid justifyContent="center" container>
      <Grid item xs={12} sm={12} sx={styles.parallaxContainer}>
        <Parallax
          blur={{min: -15, max: 15}}
          bgImage={field}
          bgImageAlt="field"
          strength={-200}
          style={styles.parallax}
          bgImageStyle={styles.bgImage}>
          <Grid
            container
            justifyContent="flex-start"
            sx={styles.titleContainer}>
            <Grid item xs={8} sm={5}>
              <Typography
                variant="h2"
                color="secondary"
                sx={{textAlign: 'left'}}>
                Nuestros servicios
              </Typography>
              <Typography variant="h6" color="secondary" sx={styles.subtitle}>
                Buscando excelencia en todo lo que hacemos
              </Typography>
            </Grid>
          </Grid>
        </Parallax>
      </Grid>
    </Grid>
  );
}

export default EntryImage;
