import React from 'react';
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Button,
  Snackbar,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// Constants
import teamInfo from './TeamInfo';

// My Components
import TeamDialog from './TeamDialog';
import TeamDialogResume from './TeamDialogResume';

const styles = {
  centerText: {
    textAlign: 'center',
  },
  marginComponent: {
    marginTop: '5rem',
  },
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export function Team() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = React.useState(false);
  const [openResume, setOpenResume] = React.useState(false);
  const [teamMember, setTeamMember] = React.useState(null);
  const [resume, setResume] = React.useState(null);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);

  const handleClickOpen = teamMember => {
    setOpen(true);
    setTeamMember(teamMember);
  };

  const handleClose = () => {
    setOpen(false);
    setTeamMember(null);
  };

  const handleClickOpenResume = resume => {
    setOpenResume(true);
    setResume(resume);
  };

  const handleCloseResume = () => {
    setOpenResume(false);
    setResume(null);
  };

  const handleClickSnackBar = () => {
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };

  return (
    <Grid justifyContent="center" container>
      <Grid item xs={12} sm={12} sx={styles.centerText}>
        <Typography variant="h2" color="primary">
          Nuestro equipo
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8} sx={styles.marginComponent}>
        <Grid container justifyContent="center" spacing={4}>
          {teamInfo.map((item, index) => {
            return (
              <Grid item xs={10} sm={6} lg={4} key={index}>
                <Card>
                  <CardActionArea
                    onClick={() => {
                      handleClickOpen(item);
                    }}>
                    <CardMedia
                      component="img"
                      height="300"
                      image={item.image}
                      alt={item.name}
                    />
                    <CardContent>
                      <Typography variant="h6">{item.name}</Typography>
                      <Typography
                        gutterBottom
                        variant={'subtitle1'}
                        color={'text.secondary'}>
                        {item.position}
                      </Typography>
                      <Typography
                        gutterBottom
                        variant={'subtitle2'}
                        color={'text.secondary'}>
                        {item.degree}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      onClick={() => {
                        if (matches) {
                          handleClickSnackBar();
                        } else {
                          handleClickOpenResume(item);
                        }
                      }}>
                      Currículum
                    </Button>
                    <Button
                      onClick={() => {
                        handleClickOpen(item);
                      }}>
                      Más información
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
          <Grid item xs={12} sm={6} />
        </Grid>
      </Grid>
      <TeamDialog
        open={open}
        teamMember={teamMember}
        handleClose={handleClose}
      />
      <TeamDialogResume
        open={openResume}
        resume={resume}
        handleClose={handleCloseResume}
      />
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}>
        <Alert
          onClose={handleCloseSnackBar}
          severity="info"
          sx={{width: '100%'}}>
          Abrir en PC para mejor visualización
        </Alert>
      </Snackbar>
    </Grid>
  );
}

export default Team;
