import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Snackbar} from '@mui/material';
import MuiAlert from '@mui/material/Alert';

// My components
import LearningPdfDialog from './LearningPdfDialog';
import LearningVideoDialog from './LearningVideoDialog';
import LearningTextDialog from './LearningTextDialog';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function LearningCard(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [openPdf, setOpenPdf] = React.useState(false);
  const [openVideo, setOpenVideo] = React.useState(false);
  const [openMoreInfo, setOpenMoreInfo] = React.useState(false);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);

  const handleClickOpenPdf = () => {
    setOpenPdf(true);
  };

  const handleClosePdf = () => {
    setOpenPdf(false);
  };

  const handleClickOpenVideo = () => {
    setOpenVideo(true);
  };

  const handleCloseVideo = () => {
    setOpenVideo(false);
  };

  const handleClickOpenMoreInfo = () => {
    setOpenMoreInfo(true);
  };

  const handleCloseMoreInfo = () => {
    setOpenMoreInfo(false);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };

  const handleClickSnackBar = () => {
    setOpenSnackBar(true);
  };

  return (
    <React.Fragment>
      <Card sx={{maxWidth: 345}}>
        <CardMedia
          component="img"
          alt="green iguana"
          height="140"
          image={props.imageCard}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {props.title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {props.desciption}
          </Typography>
        </CardContent>
        <CardActions>
          {props.pdf ? (
            <Button
              size="small"
              onClick={() => {
                if (matches) {
                  handleClickSnackBar();
                } else {
                  handleClickOpenPdf();
                }
              }}>
              PDF
            </Button>
          ) : null}
          {props.video ? (
            <Button size="small" onClick={handleClickOpenVideo}>
              Video
            </Button>
          ) : null}
          {props.moreInfo ? (
            <Button size="small" onClick={handleClickOpenMoreInfo}>
              Diagnóstico
            </Button>
          ) : null}
        </CardActions>
      </Card>
      {openPdf ? (
        <LearningPdfDialog
          handleClickOpen={handleClickOpenPdf}
          handleClose={handleClosePdf}
          open={openPdf}
          pdf={props.pdf}
          title={props.dialogTitle}
        />
      ) : null}
      {openVideo ? (
        <LearningVideoDialog
          handleClickOpen={handleClickOpenVideo}
          handleClose={handleCloseVideo}
          open={openVideo}
          video={props.video}
          title={props.dialogTitle}
        />
      ) : null}
      {openMoreInfo ? (
        <LearningTextDialog
          open={openMoreInfo}
          moreInfo={props.moreInfo}
          handleClickOpen={handleClickOpenMoreInfo}
          handleClose={handleCloseMoreInfo}
          title={props.dialogTitle}
        />
      ) : null}
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}>
        <Alert
          onClose={handleCloseSnackBar}
          severity="info"
          sx={{width: '100%'}}>
          Abrir en PC para mejor visualización
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}
