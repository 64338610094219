import carbon from '../../../resources/learning/papa/carbon.jpeg';
import carbonPDF from '../../../resources/learning/papa/carbon.pdf';

// Control - Carbón de la papa
import control_carbon_image_1 from '../../../resources/learning/papa/carbon/control/image_1.jpeg';
import control_carbon_image_2 from '../../../resources/learning/papa/carbon/control/image_2.jpeg';
import control_carbon_image_3 from '../../../resources/learning/papa/carbon/control/image_3.jpeg';
import control_carbon_image_4 from '../../../resources/learning/papa/carbon/control/image_4.jpeg';

// Diagnosis - Carbón de la papa
import diagnosis_carbon_image_1 from '../../../resources/learning/papa/carbon/diagnosis/image_1.jpeg';
import diagnosis_carbon_image_2 from '../../../resources/learning/papa/carbon/diagnosis/image_2.jpeg';
import diagnosis_carbon_image_3 from '../../../resources/learning/papa/carbon/diagnosis/image_3.jpeg';
import diagnosis_carbon_image_4 from '../../../resources/learning/papa/carbon/diagnosis/image_4.jpeg';
import diagnosis_carbon_image_5 from '../../../resources/learning/papa/carbon/diagnosis/image_5.jpeg';
import diagnosis_carbon_image_6 from '../../../resources/learning/papa/carbon/diagnosis/image_6.jpeg';
import diagnosis_carbon_image_7 from '../../../resources/learning/papa/carbon/diagnosis/image_7.jpeg';
import diagnosis_carbon_image_8 from '../../../resources/learning/papa/carbon/diagnosis/image_8.jpeg';

// Prevention - Carbón de la papa
import prevention_carbon_image_1 from '../../../resources/learning/papa/carbon/prevention/image_1.jpeg';
import prevention_carbon_image_2 from '../../../resources/learning/papa/carbon/prevention/image_2.jpeg';
import prevention_carbon_image_3 from '../../../resources/learning/papa/carbon/prevention/image_3.jpeg';
import prevention_carbon_image_4 from '../../../resources/learning/papa/carbon/prevention/image_4.jpeg';
import prevention_carbon_image_5 from '../../../resources/learning/papa/carbon/prevention/image_5.jpeg';

const papa = {
  title: 'Papa',
  cards: [
    {
      title: 'El carbón de la papa',
      description:
        'El carbón de la papa es una de las enfermedades cuarentenarias más complejas y severas que afectan al cultivo. Es causada por el hongo Thecaphora...',
      dialogTitle: 'El carbón de la papa',
      imageCard: carbon,
      pdf: carbonPDF,
      video: null,
      moreInfo: {
        control: [
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value:
                  '¡El carbón de la papa no cuenta con métodos de control efectivos!',
              },
            ],
            image: control_carbon_image_1,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Rotación de cultivos',
              },
              {
                type: 'body',
                value: [
                  'La rotación de cultivos ayuda a disminuir la enfermedad.',
                  'Sin embargo, se requiere de una rotación larga, de a lo menos 5-6años sin papa ni otra solanácea, para lograr un buen resultado.',
                  'Además, se debe realizar un efectivo control de malezas en todos los cultivos, especialmente de las hospederas (chamico, tomatillo).',
                ],
              },
            ],
            image: control_carbon_image_2,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Resistencia genética',
              },
              {
                type: 'body',
                value: [
                  'Aún no se logra una variedad resistente dentro del tipo de papa cultivado en Chile.',
                  'Sin embargo, la variedad Asterix, es la única de las variedades comerciales actuales, que ha manifestado en campo una alta resistencia al carbón.',
                ],
              },
            ],
            image: control_carbon_image_3,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Control químico',
              },
              {
                type: 'body',
                value: [
                  'No existe un control químico efectivo del carbón de la papa.',
                  'Algunos desinfectantes de semilla NO RECOMENDADOS EN PAPA, han logrado disminuir en forma significativa la producción de agallas y aumentar el rendimiento, en trabajos experimentales. Estos son: Vincit Flo e Indar Flo, pero el primero causó problemas de germinación de tubérculos.',
                ],
              },
            ],
            image: control_carbon_image_4,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value:
                  'El mejor control del carbón de la papa es: La prevención',
              },
              {
                type: 'body',
                value: [
                  'No emplear tubérculos semilla provenientes de zonas afectadas, especialmente de la zona norte y centro sur del país, al igual que la zona de Carahue - Saavedra de la Región de la Araucanía.',
                  'Denunciar al SAG, la presencia de plantas tubérculo con agallas, o de plantas sospechosas.',
                  'No permitir el ingreso a los predios, de maquinaria agrícola sucia, con tierra o que haya efectuado labores en predios infestados.',
                  'No permitir el ingreso de animales que provengan de áreas infestadas.',
                  'Recoger las agallas e incendiarlas. No darlas como alimento a los animales.',
                  'No emplear sacos usados para cosechar y guardar las papas.',
                  'Controlar especialmente malezas como chamico y tomatillo.',
                ],
              },
            ],
            image: null,
          },
        ],
        diagnosis: [
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Diagnóstico',
              },
            ],
            image: diagnosis_carbon_image_1,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Diagnóstico',
              },
            ],
            image: diagnosis_carbon_image_2,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'La enfermedad sólo se detecta en la cosecha',
              },
              {
                type: 'body',
                value: [
                  'Siembra de papas en la IV Región, afectada por carbón.',
                  '¡No se observan síntomas aéreos!',
                ],
              },
            ],
            image: diagnosis_carbon_image_3,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'Cosecha de papas en un suelo altamente infestado, en el sector de Cerrillos, Pan de Azúcar, IV Región.',
                ],
              },
            ],
            image: diagnosis_carbon_image_4,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value:
                  'Es una enfermedad asociada al suelo. Permanece activa en el suelo por más de 10 años.',
              },
              {
                type: 'body',
                value: [
                  'Una agalla de carbón (arriba) y un tubérculo de papa (abajo).',
                ],
              },
            ],
            image: diagnosis_carbon_image_5,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value:
                  'Inicio de la enfermedad en los tubérculos es casi imperceptible.',
              },
              {
                type: 'body',
                value: [
                  'En los tubérculos se desarrollan tumores desde un tamaño significativo (2-8 cm), hasta tumores casi imperceptibles (1 mm).',
                ],
              },
            ],
            image: diagnosis_carbon_image_6,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: ['Agallas de gran tamaño adosadas a los tubérculos.'],
              },
            ],
            image: diagnosis_carbon_image_7,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: ['Numerosas agallas en una planta de papa.'],
              },
            ],
            image: diagnosis_carbon_image_8,
          },
        ],
        prevention: [
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Se transmite a través de la semilla',
              },
            ],
            image: prevention_carbon_image_1,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value:
                  'Se disemina a través de múltiples vías como las que se presentan aquí',
              },
            ],
            image: prevention_carbon_image_2,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Se desarrolla y mantiene en malezas comunes',
              },
            ],
            image: prevention_carbon_image_3,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value:
                  'También se desarrolla y mantiene en cultivos como tomate',
              },
            ],
            image: prevention_carbon_image_4,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Medidas de prevención',
              },
              {
                type: 'body',
                value: [
                  'No emplear tubérculos semilla provenientes de la zona de riesgo primario de la IX región, como tampoco de eventuales e ilegales partidas de la zona norte.',
                  'Denuncias la presencia de plantas o tubérculos con agallas, o de plantas sospechosas.',
                  'No permitir el ingreso a los predios, de maquinaria agrícola que haya efectuado labores en predios infestados.',
                  'No permitir el ingreso de animales que provengan de áreas infestadas.',
                  'Recoger las agallas e incendiarlas. No darlas como alimento a los animales.',
                  'No emplear sacos usados para cosechar y guardar las papas.',
                  'Controlar especialmente malezas como chamico y tomatillo.',
                  'Conversar con los vecinos sobre este problema, y promover y practicar todas las medidas de prevención',
                ],
              },
            ],
            image: null,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value:
                  'Se debe evitar que el carbón de la papa se instale en la zona sur de Chile.',
              },
            ],
            image: prevention_carbon_image_5,
          },
        ],
      },
    },
  ],
};

export default papa;
