import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Grid, Typography, Button, Divider} from '@mui/material';

import joinStyles from './helpers/LearningHelper';

import trigo from '../../../resources/trigo.jpg';
import cebada from '../../../resources/cebada.jpg';
import raps from '../../../resources/raps.jpeg';
import papa from '../../../resources/papa.jpg';
import avena from '../../../resources/avena.jpg';
import lupino from '../../../resources/lupino.jpeg';

const Styles = {
  default_style: {
    transition: 'transform .5s, filter 1.5s ease-in-out',
    width: '100%',
    height: '50vh',
    maxWidth: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
    position: 'relative',
    transform: 'scale(1.1)',
    filter: 'brightness(70%)',
  },
  hover_style: {
    transform: 'scale(1.0)',
    filter: 'blur(2px) brightness(70%)',
  },
  hover_container: {
    overflow: 'hidden',
    padding: '0!important',
    border: '1rem solid',
    borderColor: 'transparent',
    position: 'relative',
  },
  hover_text: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '50vh',
  },
};

export function Learning() {
  const navigate = useNavigate();
  const [hoverTrigo, setHoverTrigo] = React.useState(false);
  const [hoverCebada, setHoverCebada] = React.useState(false);
  const [hoverRaps, setHoverRaps] = React.useState(false);
  const [hoverPapa, setHoverPapa] = React.useState(false);
  const [hoverAvena, setHoverAvena] = React.useState(false);
  const [hoverLupino, setHoverLupino] = React.useState(false);

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      alignItems="center">
      <Grid item xs={12} sm={6} sx={Styles.hover_container}>
        <div
          style={joinStyles(
            Styles.default_style,
            hoverTrigo && Styles.hover_style,
          )}>
          <img
            alt={'trigo'}
            src={trigo}
            style={{width: 'auto', height: '60vh'}}
          />
        </div>
        <div
          style={Styles.hover_text}
          onMouseEnter={() => setHoverTrigo(true)}
          onMouseLeave={() => setHoverTrigo(false)}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{height: '100%'}}>
            <Grid item xs={12} sm={12}>
              <Typography
                color="secondary"
                align="center"
                sx={{width: '100%', paddingBottom: '1rem'}}
                variant="h3">
                Trigo
              </Typography>
              {hoverTrigo ? (
                <Divider variant="middle" light>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() =>
                      navigate('/learning', {state: {section: 'Trigo'}})
                    }>
                    Aprender
                  </Button>
                </Divider>
              ) : null}
            </Grid>
          </Grid>
        </div>
      </Grid>

      <Grid item xs={12} sm={3} sx={Styles.hover_container}>
        <div
          style={joinStyles(
            Styles.default_style,
            hoverCebada && Styles.hover_style,
          )}>
          <img
            alt={'cebada'}
            src={cebada}
            style={{width: 'auto', height: '60vh'}}
          />
        </div>
        <div
          style={Styles.hover_text}
          onMouseEnter={() => setHoverCebada(true)}
          onMouseLeave={() => setHoverCebada(false)}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{height: '100%'}}>
            <Grid item xs={12} sm={12}>
              <Typography
                color="secondary"
                align="center"
                sx={{width: '100%', paddingBottom: '1rem'}}
                variant="h3">
                Cebada
              </Typography>
              {hoverCebada ? (
                <Divider variant="middle" light>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() =>
                      navigate('/learning', {state: {section: 'Cebada'}})
                    }>
                    Aprender
                  </Button>
                </Divider>
              ) : null}
            </Grid>
          </Grid>
        </div>
      </Grid>

      <Grid item xs={12} sm={3} sx={Styles.hover_container}>
        <div
          style={joinStyles(
            Styles.default_style,
            hoverRaps && Styles.hover_style,
          )}>
          <img alt="raps" src={raps} style={{width: 'auto', height: '60vh'}} />
        </div>
        <div
          style={Styles.hover_text}
          onMouseEnter={() => setHoverRaps(true)}
          onMouseLeave={() => setHoverRaps(false)}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{height: '100%'}}>
            <Grid item xs={12} sm={12}>
              <Typography
                color="secondary"
                align="center"
                sx={{width: '100%', paddingBottom: '1rem'}}
                variant="h3">
                Raps
              </Typography>
              {hoverRaps ? (
                <Divider variant="middle" light>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() =>
                      navigate('/learning', {state: {section: 'Raps'}})
                    }>
                    Aprender
                  </Button>
                </Divider>
              ) : null}
            </Grid>
          </Grid>
        </div>
      </Grid>

      <Grid item xs={12} sm={3} sx={Styles.hover_container}>
        <div
          style={joinStyles(
            Styles.default_style,
            hoverPapa && Styles.hover_style,
          )}>
          <img alt="papa" src={papa} style={{width: 'auto', height: '60vh'}} />
        </div>
        <div
          style={Styles.hover_text}
          onMouseEnter={() => setHoverPapa(true)}
          onMouseLeave={() => setHoverPapa(false)}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{height: '100%'}}>
            <Grid item xs={12} sm={12}>
              <Typography
                color="secondary"
                align="center"
                sx={{width: '100%', paddingBottom: '1rem'}}
                variant="h3">
                Papa
              </Typography>
              {hoverPapa ? (
                <Divider variant="middle" light>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() =>
                      navigate('/learning', {state: {section: 'Papa'}})
                    }>
                    Aprender
                  </Button>
                </Divider>
              ) : null}
            </Grid>
          </Grid>
        </div>
      </Grid>

      <Grid item xs={12} sm={3} sx={Styles.hover_container}>
        <div
          style={joinStyles(
            Styles.default_style,
            hoverAvena && Styles.hover_style,
          )}>
          <img
            alt="avena"
            src={avena}
            style={{width: 'auto', height: '50vh'}}
          />
        </div>
        <div
          style={Styles.hover_text}
          onMouseEnter={() => setHoverAvena(true)}
          onMouseLeave={() => setHoverAvena(false)}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{height: '100%'}}>
            <Grid item xs={12} sm={12}>
              <Typography
                color="secondary"
                align="center"
                sx={{width: '100%', paddingBottom: '1rem'}}
                variant="h3">
                Avena
              </Typography>
              {hoverAvena ? (
                <Divider variant="middle" light>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() =>
                      navigate('/learning', {state: {section: 'Avena'}})
                    }>
                    Aprender
                  </Button>
                </Divider>
              ) : null}
            </Grid>
          </Grid>
        </div>
      </Grid>

      <Grid item xs={12} sm={6} sx={Styles.hover_container}>
        <div
          style={joinStyles(
            Styles.default_style,
            hoverLupino && Styles.hover_style,
          )}>
          <img
            alt="lupino"
            src={lupino}
            style={{width: 'auto', height: '60vh'}}
          />
        </div>
        <div
          style={Styles.hover_text}
          onMouseEnter={() => setHoverLupino(true)}
          onMouseLeave={() => setHoverLupino(false)}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{height: '100%'}}>
            <Grid item xs={12} sm={12}>
              <Typography
                color="secondary"
                align="center"
                sx={{width: '100%', paddingBottom: '1rem'}}
                variant="h3">
                Lupino
              </Typography>
              {hoverLupino ? (
                <Divider variant="middle" light>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() =>
                      navigate('/learning', {state: {section: 'Lupino'}})
                    }>
                    Aprender
                  </Button>
                </Divider>
              ) : null}
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
}

export default Learning;
