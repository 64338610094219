import roya from '../../../resources/learning/avena/roya.jpeg';
import halo from '../../../resources/learning/avena/halo.jpeg';

// Diagnostico - Roya
import diagnosis_roya_main from '../../../resources/learning/avena/roya/diagnosis/main.jpeg';
import diagnosis_roya_image_1 from '../../../resources/learning/avena/roya/diagnosis/image_1.jpeg';
import diagnosis_roya_image_2 from '../../../resources/learning/avena/roya/diagnosis/image_2.jpeg';
import diagnosis_roya_image_3 from '../../../resources/learning/avena/roya/diagnosis/image_3.jpeg';
import diagnosis_roya_image_4 from '../../../resources/learning/avena/roya/diagnosis/image_4.jpeg';
import diagnosis_roya_image_5 from '../../../resources/learning/avena/roya/diagnosis/image_5.jpeg';

// Prevention - Roya
import prevention_roya_image_1 from '../../../resources/learning/avena/roya/prevention/image_1.jpeg';
import prevention_roya_image_2 from '../../../resources/learning/avena/roya/prevention/image_2.jpeg';
import prevention_roya_image_3 from '../../../resources/learning/avena/roya/prevention/image_3.jpeg';

// Control - Roya
import control_roya_image_1 from '../../../resources/learning/avena/roya/control/image_1.jpeg';
import control_roya_image_2 from '../../../resources/learning/avena/roya/control/image_2.jpeg';
import control_roya_image_3 from '../../../resources/learning/avena/roya/control/image_3.jpeg';
import control_roya_image_4 from '../../../resources/learning/avena/roya/control/image_4.jpeg';

// Control - Tizon
import control_tizon_image_1 from '../../../resources/learning/avena/tizon/control/image_1.jpeg';
//import control_tizon_image_2 from '../../../resources/learning/avena/tizon/control/image_2.jpeg';
import control_tizon_image_3 from '../../../resources/learning/avena/tizon/control/image_3.jpeg';
import control_tizon_image_4 from '../../../resources/learning/avena/tizon/control/image_4.jpeg';
import control_tizon_image_5 from '../../../resources/learning/avena/tizon/control/image_5.jpeg';
import control_tizon_image_6 from '../../../resources/learning/avena/tizon/control/image_6.jpeg';

// Diagnostico - Tizon
import diagnosis_tizon_image_1 from '../../../resources/learning/avena/tizon/diagnosis/image_1.jpeg';
import diagnosis_tizon_image_2 from '../../../resources/learning/avena/tizon/diagnosis/image_2.jpeg';
import diagnosis_tizon_image_3 from '../../../resources/learning/avena/tizon/diagnosis/image_3.jpeg';
import diagnosis_tizon_image_4 from '../../../resources/learning/avena/tizon/diagnosis/image_4.jpeg';
import diagnosis_tizon_image_5 from '../../../resources/learning/avena/tizon/diagnosis/image_5.jpeg';
import diagnosis_tizon_image_6 from '../../../resources/learning/avena/tizon/diagnosis/image_6.jpeg';

// Prevencion - Tizon
import prevention_tizon_image_1 from '../../../resources/learning/avena/tizon/prevention/image_1.jpeg';
import prevention_tizon_image_2 from '../../../resources/learning/avena/tizon/prevention/image_2.jpeg';
import prevention_tizon_image_3 from '../../../resources/learning/avena/tizon/prevention/image_3.jpeg';
import prevention_tizon_image_4 from '../../../resources/learning/avena/tizon/prevention/image_4.jpeg';
import prevention_tizon_image_5 from '../../../resources/learning/avena/tizon/prevention/image_5.jpeg';
import prevention_tizon_image_6 from '../../../resources/learning/avena/tizon/prevention/image_6.jpeg';

const avena = {
  title: 'Avena',
  cards: [
    // Roya
    {
      title: 'La roya',
      description:
        'Los primeros síntomas corresponden a pústulas individuales, pequeñas, a veces con un pequeño halo translúcido alrededor de la pústula, que se...',
      dialogTitle: 'La roya o polvillo de la hoja de la avena',
      imageCard: roya,
      pdf: null,
      video: null,
      moreInfo: {
        control: [
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Comentario general',
              },
              {
                type: 'body',
                value: [
                  'La avena, hasta hace algunos años atrás, no era tratada con fungicidas en el sur de Chile, a excepción de la desinfección de la semilla.',
                  'Sin embargo, aspectos de precio, manejo del cultivo, rentabilidad, entre otros, han derivado en el uso de fungicidas bajo ciertas condiciones y en algunas temporadas.',
                  'El uso de fungicidas ha estado dirigido a enfermedades como el oidio y la roya, las cuales suelen aumentar su incidencia y severidad bajo condiciones de baja ventilación del cultivo (cultivo denso, sectores protegidos del potrero), alta fertilización nitrogenada, humedad relativa alta con temperaturas cálidas, entre otras.',
                ],
              },
            ],
            image: control_roya_image_1,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Variedades resistentes',
              },
              {
                type: 'subtitle',
                value: 'Elija una variedad resistente.',
              },
              {
                type: 'body',
                value: [
                  'La mejor alternativa para evitar la roya de la hoja es el empleo de una variedad resistente.',
                  'Las variedades resistentes no son afectadas por la enfermedad. Además, no requieren de tratamientos fungicida lo cual significa un menor costo de producción.',
                ],
              },
            ],
            image: control_roya_image_2,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Desinfección de semilla',
              },
              {
                type: 'subtitle',
                value:
                  'Desinfecte la semilla para evitar y/o retardar infecciones.',
              },
              {
                type: 'body',
                value: [
                  'La desinfección de la semilla evita enfermedades como el carbón.',
                  'En siembras de primavera, algunos desinfectantes de largo efecto residual podrían además, retardar el inicio de infecciones tempranas como la roya de la hoja y oidio. Sin embargo, esta última alternativa no ha sido evaluada en avena.',
                ],
              },
            ],
            image: control_roya_image_3,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Aplicar fungicidas en el momento oportuno',
              },
              {
                type: 'subtitle',
                value:
                  'La roya de la hoja debe ser controlada al momento de ser detectadas las primeras pústulas, en variedades de conocida susceptibilidad.',
              },
              {
                type: 'body',
                value: [
                  'Recuerde que la aplicación foliar de fungicidas tiene su momento oportuno. El realizarlo en estados de desarrollo inadecuados, o con productos de baja eficacia, sólo se traducirá en un mal resultado económico.',
                ],
              },
            ],
            image: control_roya_image_4,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value:
                  '¿Con qué fungicidas se puede controlar La Roya de la hoja?',
              },
              {
                type: 'body',
                value: [
                  'Consulte con su asesor profesional por el fungicida más adecuado.',
                  'Exija siempre antecedentes de eficacia del fungicida recomendado, principalmente de indicadores o notas de infección, obtenidos por profesionales calificados.',
                  'Eventualmente, deje un pequeño sector del cultivo (lejos de arboledas o bordes de caminos) sin tratar, para verificar la eficacia del producto recomendado.',
                ],
              },
            ],
            image: null,
          },
        ],
        diagnosis: [
          {
            type: 'main',
            title: 'Organismo causal: ',
            causeTitle: 'Hongo',
            subtitle: 'Nombre del hongo: ',
            causeSubtitle: 'Puccinia coronata f. sp. avenae',
            image: diagnosis_roya_main,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'Los primeros síntomas corresponden a pústulas individuales, pequeñas, a veces con un pequeño halo translúcido alrededor de la pústula, que se presentan en cualquier parte de la lámina foliar.',
                ],
              },
            ],
            image: diagnosis_roya_image_1,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'En dos o tres días, según la reacción de la variedad, aparecerán más pústulas, en diversos sectores de la hoja.',
                  'Estas pústulas pueden ser grandes y llamativas en variedades susceptibles, o pequeñas en variedades más tolerantes.',
                ],
              },
            ],
            image: diagnosis_roya_image_2,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'En condiciones predisponentes a la infección (variedad susceptible, alta fertilización nitrogenada, sectores del potrero de baja ventilación, temperaturas cálidas), los síntomas se tornan severos pudiendo las pústulas cubrir gran parte o la totalidad del área foliar.',
                ],
              },
            ],
            image: diagnosis_roya_image_3,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'Las pústulas son aquellas estructuras pulverulentas de color anaranjado rojizo, que se observan sobre las hojas.',
                  'Corresponden a la erupción de las estructuras de diseminación del hongo causal , a través de la epidermis de las hojas, rompiendo la cutícula, para dejar expuestas a las uredosporas las cuales, liberadas por el viento, irán a infectar otras hojas y plantas.',
                ],
              },
            ],
            image: diagnosis_roya_image_4,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'Uredosporas de Puccinia coronata f. sp. avenae observadas al microscopio (derecha). Son las estructuras de diseminación que acarreadas por el aire, se depositarán en otras hojas y plantas de avena para iniciar nuevas infecciones.',
                ],
              },
            ],
            image: diagnosis_roya_image_5,
          },
        ],
        prevention: [
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Variedades resistentes',
              },
              {
                type: 'subtitle',
                value:
                  'La mejor alternativa de prevención de la roya de la hoja de la avena, es la elección de una variedad resistente.',
              },
              {
                type: 'body',
                value: [
                  'De esa forma evitará la presencia de la enfermedad, los costos asociados a su control y, las eventuales pérdidas que un control inoportuno puede causar.',
                ],
              },
            ],
            image: prevention_roya_image_1,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'No exceda fertilización nitrogenada',
              },
              {
                type: 'subtitle',
                value:
                  'Un exceso de fertilización nitrogenada es anti económico y contra producente. Fertilice de acuerdo al análisis de suelo y a lo que la planta necesita.',
              },
              {
                type: 'body',
                value: [
                  'El exceso de nitrógeno retarda la lignificación y madurez de los tejidos, favorece una mayor masa vegetativa, aumentando así la humedad relativa. Por tanto, predispone a una mayor severidad de la enfermedad.',
                ],
              },
            ],
            image: prevention_roya_image_2,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Desinfección de la semilla',
              },
              {
                type: 'subtitle',
                value:
                  'Desinfecte siempre la semilla para evitar enfermedades como el carbón. Algunos desinfectantes de semilla además, podrían retardar el inicio de algunas infecciones.',
              },
              {
                type: 'body',
                value: [
                  'A través del empleo de algunos desinfectantes de semilla sistémicos y de largo efecto residual, se logra proteger a las plantas durante los primeros estados de desarrollo y retardar el inicio de la infección de algunas enfermedades.',
                  'Sin embargo, en nuestro país no se ha evaluado esta alternativa de prevención en avena.',
                ],
              },
            ],
            image: prevention_roya_image_3,
          },
        ],
      },
    },
    // Halo
    {
      title: 'Tizon',
      description:
        'Los primeros síntomas corresponden a manchas cloróticas, semi translúcidas, con un área necrótica rodeada de un halo de apariencia algo acuosa.',
      dialogTitle: 'Tizon o halo bacteriano de la avena',
      imageCard: halo,
      pdf: null,
      video: null,
      moreInfo: {
        control: [
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Comentario general',
              },
              {
                type: 'subtitle',
                value:
                  'El tizón o halo bacteriano de la avena es una enfermedad de bajo impacto en la producción de avena en el sur de Chile.',
              },
              {
                type: 'body',
                value: [
                  'Por lo general no requiere de medidas de control, pues una vez que disminuyen las lluvias, los síntomas de la enfermedad tienden a desaparecer. Las medidas son más bien de tipo preventivo.',
                  'Eventualmente, en temporadas con salidas de invierno y primaveras frías y lluviosas, se podrá apreciar una importante necrosis foliar en siembras de otoño, en variedades susceptibles a la enfermedad. Sin embargo, a medida que avanza la temporada, los síntomas desaparecen.',
                  'A veces se observarán panojas blancas, podridas, por efecto de la infección en la parte superior del tallo. Pero no se ha observado esta sintomatología con una alta incidencia.',
                  'Avenas para producción de grano y producción animal, podrán diferir en cuanto al manejo de esta enfermedad.',
                ],
              },
            ],
            image: control_tizon_image_1,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Variedades resistentes o tolerantes',
              },
              {
                type: 'subtitle',
                value:
                  'Una buena alternativa para evitar el tizón o halo bacteriano de la avena, es la elección de una variedad resistente o tolerante.',
              },
              {
                type: 'body',
                value: [
                  'Avenas para talaje debieran preferir variedades resistentes o tolerantes, para evitar el daño foliar en temporadas frías y lluviosas.',
                  'En avenas para grano, el halo bacteriano es muy poco significativo, por tanto la variedad es menos importante desde el punto de vista de esta enfermedad.',
                ],
              },
            ],
            image: control_tizon_image_3,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Rotación de cultivos',
              },
              {
                type: 'subtitle',
                value:
                  'La rotación de cultivos es una eficaz medidas de prevención y control.',
              },
              {
                type: 'body',
                value: [
                  'Disminuye la población de la bacteria en el suelo y rastrojos, con lo cual se reduce significativamente la severidad del ataque.',
                ],
              },
            ],
            image: control_tizon_image_4,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Desinfección de semilla',
              },
              {
                type: 'subtitle',
                value:
                  'Desinfecte siempre la semilla para evitar enfermedades como el carbón y otras.\n',
              },
              {
                type: 'body',
                value: [
                  'Algunos desinfectantes de semilla en base a cobre, previenen el inicio temprano de la infección del halo bacteriano de la avena, al eliminar las bacterias acarreadas externamente en la semilla.',
                ],
              },
            ],
            image: control_tizon_image_5,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Aplicación foliar de bactericidas',
              },
              {
                type: 'subtitle',
                value:
                  'La aplicación de bactericidas en forma foliar puede lograr disminuir la infección, pero su impacto es de difícil pronóstico pues se desconoce la magnitud del daño en nuestro país.',
              },
              {
                type: 'body',
                value: [
                  'Algunos fungicidas foliares en base a cobre pueden disminuir la infección foliar, pero se desconoce en impacto en el rendimiento y calidad del grano. Podrían tener mayor impacto en avenas para talaje o ensilaje, pero aún así no hay información que permita sustentarlo.',
                  'Un aspecto importante de considerar, es que algunos fungicidas en base a cobre pueden ser fitotóxicos en la avena, como el óxido cuproso.',
                ],
              },
            ],
            image: control_tizon_image_6,
          },
        ],
        diagnosis: [
          {
            type: 'main',
            title: 'Organismo causal: ',
            causeTitle: 'Bacteria',
            subtitle: 'Nombre de la bacteria: ',
            causeSubtitle: 'Pseudomonas syringae pv. coronafaciens',
            image: diagnosis_roya_main,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'Los primeros síntomas corresponden a manchas cloróticas, semi translúcidas, con un área necrótica rodeada de un halo de apariencia algo acuosa.',
                ],
              },
            ],
            image: diagnosis_tizon_image_1,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'Las lesiones foliares aumentan de tamaño, creciendo hacia ambos extremos de la hoja, manteniendo los halos semi acuosos alrededor de las lesiones. El centro de éstas va adquiriendo una coloración más café y comienza a aparecer necrosis más severas de los tejidos foliares por sobre las lesiones, debido al bloqueo de agua y nutrientes.',
                ],
              },
            ],
            image: diagnosis_tizon_image_2,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'En estados más avanzados de la infección, las lesiones comprometen gran parte o la totalidad de la hoja, con una severa necrosis de tonalidades blanquecino-amarillentas a café. Suele observarse una línea central necrótica a lo largo de la lesión',
                ],
              },
            ],
            image: diagnosis_tizon_image_3,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'Los síntomas pueden diferir en otras variedades , con lesiones más pequeñas inicialmente, ovaladas a irregulares, con severa necrosis del tejido, pudiendo luego extenderse igualmente a gran parte de la lámina foliar o bien, permanecer de ese tamaño tomando una coloración más oscura.',
                ],
              },
            ],
            image: diagnosis_tizon_image_4,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'En primaveras frías y con alta pluviometría, la infección puede afectar gran parte del área foliar y a un alto número de plantas en una siembra.',
                ],
              },
            ],
            image: diagnosis_tizon_image_5,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'Cuando se afecta en forma severa la hoja bandera, la planta puede producir panojas blancas, coloración que se debe a la pudrición causada por la infección en la parte superior del tallo.',
                ],
              },
            ],
            image: diagnosis_tizon_image_6,
          },
        ],
        prevention: [
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Comentario general',
              },
              {
                type: 'subtitle',
                value:
                  'El tizón o halo bacteriano de la avena es una enfermedad de bajo impacto en la producción de avena en el sur de Chile.',
              },
              {
                type: 'body',
                value: [
                  'Por lo general no requiere de medidas de control, pues una vez que disminuyen las lluvias, los síntomas de la enfermedad tienden a desaparecer.',
                  'Eventualmente, en temporadas con salidas de invierno y primaveras frías y lluviosas, se podrá apreciar una importante necrosis foliar en siembras de otoño, en variedades susceptibles a la enfermedad. Sin embargo, a medida que avanza la temporada, los síntomas desaparecen. A veces se observarán panojas blancas, podridas, por efecto de la infección en la parte superior del tallo. Pero no se ha observado esta sintomatología con una alta incidencia.',
                  'Avenas para producción de grano y producción animal, podrán diferir en cuanto al manejo de esta enfermedad.',
                ],
              },
            ],
            image: prevention_tizon_image_1,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Variedades resistentes o tolerantes',
              },
              {
                type: 'subtitle',
                value:
                  'Una buena alternativa de prevención del tizón o halo bacteriano de la avena, es la elección de una variedad resistente o tolerante.',
              },
              {
                type: 'body',
                value: [
                  'Las variedades comerciales difieren en cuanto a la expresión de la enfermedad, con comportamientos susceptibles o tolerantes.',
                  'Avenas para talaje debieran preferir variedades resistentes o tolerantes, para evitar el daño foliar en temporadas frías y lluviosas.',
                  'En avenas para grano, la enfermedad es muy poco significativa.',
                ],
              },
            ],
            image: prevention_tizon_image_2,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Rotación de cultivos',
              },
              {
                type: 'subtitle',
                value:
                  'La rotación de cultivos es una eficaz medidas de prevención.',
              },
              {
                type: 'body',
                value: [
                  'Disminuye la población de la bacteria en el suelo y rastrojos, con lo cual se reduce significativamente la severidad del ataque.',
                ],
              },
            ],
            image: prevention_tizon_image_3,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Manejo de rastrojos',
              },
              {
                type: 'subtitle',
                value: 'El rastrojo debe ser incorporado profundo.',
              },
              {
                type: 'body',
                value: [
                  'El rastrojo es la primera fuente de infección, puesto que ahí sobrevive la bacteria.',
                  'La quema del rastrojo también contribuye a disminuir las poblaciones de la bacteria.',
                  'Sin embargo, su práctica también tiene efectos indeseables para el suelo y ambiente.',
                ],
              },
            ],
            image: prevention_tizon_image_4,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'No exceda la fertilización nitrogenada',
              },
              {
                type: 'subtitle',
                value:
                  'Un exceso de fertilización nitrogenada es anti económico y contra producente. Fertilice de acuerdo al análisis de suelo y a lo que la planta necesita.',
              },
              {
                type: 'body',
                value: [
                  'El exceso de nitrógeno retarda la lignificación y madurez de los tejidos, favorece una mayor masa vegetativa, favoreciendo el avance y desarrollo de la infección. Por tanto, predispone a una mayor severidad de ésta y otras enfermedades foliares.',
                ],
              },
            ],
            image: prevention_tizon_image_5,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Desinfección de la semilla',
              },
              {
                type: 'subtitle',
                value:
                  'Desinfecte siempre la semilla para evitar enfermedades como el carbón.',
              },
              {
                type: 'body',
                value: [
                  'Algunos desinfectantes de semilla en base a cobre, previenen el inicio temprano de la infección del halo bacteriano de la avena, al eliminar las bacterias acarreadas externamente en la semilla.',
                ],
              },
            ],
            image: prevention_tizon_image_6,
          },
        ],
      },
    },
  ],
};

export default avena;
