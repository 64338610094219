import noticia_1 from '../../resources/news/noticia_1.jpeg';

const currentNews = [
  {
    title:
      'Agro del sur desarrolla nuevo pesticida para X en el marco de X Y Z asdfasdfsad',
    image: noticia_1,
    date: '12/12/2021',
  },
  {
    title:
      'Agro del sur desarrolla nuevo pesticida para X en el marco de X Y Z',
    image: noticia_1,
    date: '12/12/2021',
  },
  {
    title:
      'TAgro del sur desarrolla nuevo pesticida para X en el marco de X Y Z',
    image: noticia_1,
    date: '12/12/2021',
  },
  {
    title:
      'Agro del sur desarrolla nuevo pesticida para X en el marco de X Y Z',
    image: noticia_1,
    date: '12/12/2021',
  },
  {
    title:
      'Agro del sur desarrolla nuevo pesticida para X en el marco de X Y Z',
    image: noticia_1,
    date: '12/12/2021',
  },
  {
    title:
      'Agro del sur desarrolla nuevo pesticida para X en el marco de X Y Z',
    image: noticia_1,
    date: '12/12/2021',
  },
];

export default currentNews;
