import React, {useEffect} from 'react';
import {Grid} from '@mui/material';

// External
import EntryImage from './EntryImage';
import Values from './Values';
import Team from './Team';

const styles = {
  defaultSeparation: {
    marginTop: '5rem',
  },
};

export function Us() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid justifyContent="center" container>
      <Grid item xs={12} sm={12}>
        <EntryImage />
      </Grid>
      <Grid item xs={12} sm={12} sx={styles.defaultSeparation}>
        <Values />
      </Grid>
      <Grid item xs={12} sm={12} sx={styles.defaultSeparation}>
        <Team />
      </Grid>
    </Grid>
  );
}

export default Us;
