import rincosporiosis from '../../../resources/learning/cebada/rincosporiosis.jpeg';
import rincosporiosisPDF from '../../../resources/learning/cebada/rincosporiosis.pdf';
import mancha_en_red from '../../../resources/learning/cebada/mancha_en_red.jpeg';
import mancha_en_redPDF from '../../../resources/learning/cebada/mancha_en_red.pdf';

// Control - Rincosporiosis
import control_rincosporiosis_image_1 from '../../../resources/learning/cebada/rincosporosis/control/image_1.jpeg';
import control_rincosporiosis_image_2 from '../../../resources/learning/cebada/rincosporosis/control/image_2.jpeg';
import control_rincosporiosis_image_3 from '../../../resources/learning/cebada/rincosporosis/control/image_3.jpeg';

// Diagnosis - Rincosporiosis
import diagnosis_rincosporiosis_main from '../../../resources/learning/cebada/rincosporosis/diagnosis/main.jpeg';
import diagnosis_rincosporiosis_image_1 from '../../../resources/learning/cebada/rincosporosis/diagnosis/image_1.jpeg';
import diagnosis_rincosporiosis_image_2 from '../../../resources/learning/cebada/rincosporosis/diagnosis/image_2.jpeg';
import diagnosis_rincosporiosis_image_3 from '../../../resources/learning/cebada/rincosporosis/diagnosis/image_3.jpeg';
import diagnosis_rincosporiosis_image_4 from '../../../resources/learning/cebada/rincosporosis/diagnosis/image_4.jpeg';
import diagnosis_rincosporiosis_image_5 from '../../../resources/learning/cebada/rincosporosis/diagnosis/image_5.jpeg';

// Prevention - Rincosporiosis
import prevention_rincosporiosis_image_1 from '../../../resources/learning/cebada/rincosporosis/prevention/image_1.jpeg';
import prevention_rincosporiosis_image_2 from '../../../resources/learning/cebada/rincosporosis/prevention/image_2.jpeg';
import prevention_rincosporiosis_image_3 from '../../../resources/learning/cebada/rincosporosis/prevention/image_3.jpeg';
import prevention_rincosporiosis_image_4 from '../../../resources/learning/cebada/rincosporosis/prevention/image_4.jpeg';
import prevention_rincosporiosis_image_5 from '../../../resources/learning/cebada/rincosporosis/prevention/image_5.jpeg';
import prevention_rincosporiosis_image_6 from '../../../resources/learning/cebada/rincosporosis/prevention/image_6.jpeg';

// Control - Mancha reticulada
import control_mancha_image_1 from '../../../resources/learning/cebada/mancha_en_red/control/image_1.jpeg';
import control_mancha_image_2 from '../../../resources/learning/cebada/mancha_en_red/control/image_2.jpeg';
import control_mancha_image_3 from '../../../resources/learning/cebada/mancha_en_red/control/image_3.jpeg';

// Diagnosis - Mancha reticulada
import diagnosis_mancha_main from '../../../resources/learning/cebada/mancha_en_red/diagnosis/main.jpeg';
import diagnosis_mancha_image_1 from '../../../resources/learning/cebada/mancha_en_red/diagnosis/image_1.jpeg';
import diagnosis_mancha_image_2 from '../../../resources/learning/cebada/mancha_en_red/diagnosis/image_2.jpeg';
import diagnosis_mancha_image_3 from '../../../resources/learning/cebada/mancha_en_red/diagnosis/image_3.jpeg';
import diagnosis_mancha_image_4 from '../../../resources/learning/cebada/mancha_en_red/diagnosis/image_4.jpeg';
import diagnosis_mancha_image_5 from '../../../resources/learning/cebada/mancha_en_red/diagnosis/image_5.jpeg';

// Prevention - Mancha reticulada
import prevention_mancha_image_1 from '../../../resources/learning/cebada/mancha_en_red/prevention/image_1.jpeg';
import prevention_mancha_image_2 from '../../../resources/learning/cebada/mancha_en_red/prevention/image_2.jpeg';
import prevention_mancha_image_3 from '../../../resources/learning/cebada/mancha_en_red/prevention/image_3.jpeg';
import prevention_mancha_image_4 from '../../../resources/learning/cebada/mancha_en_red/prevention/image_4.jpeg';
import prevention_mancha_image_5 from '../../../resources/learning/cebada/mancha_en_red/prevention/image_5.jpeg';

const cebada = {
  title: 'Cebada',
  cards: [
    {
      title: 'Rincosporiosis',
      description:
        'Una enfermedad común de la cebada en la zona sur de Chile, es la rincosporiosis o escaldadura de la hoja.',
      dialogTitle: 'Rincosporiosis o Escaldadura de la hoja de la cebada',
      imageCard: rincosporiosis,
      pdf: rincosporiosisPDF,
      video:
        'https://www.youtube.com/watch?v=W88yd8_iBwM&ab_channel=AgrodelSurEstaci%C3%B3nExperimental',
      moreInfo: {
        control: [
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Variedades resistentes',
              },
              {
                type: 'subtitle',
                value:
                  'Elija una variedad resistente o tolerante a la enfermedad.',
              },
              {
                type: 'body',
                value: [
                  'Las variedades resistentes o tolerantes no son afectadas por la enfermedad, o lo son en forma muy leve.',
                  'Además, no requieren de tratamientos fungicida lo cual significa un menor costo de producción.',
                ],
              },
            ],
            image: control_rincosporiosis_image_1,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Desinfección de semilla',
              },
              {
                type: 'subtitle',
                value:
                  'Desinfecte la semilla, para eliminar la posible transmisión del hongo o retardar la infección.',
              },
              {
                type: 'body',
                value: [
                  'La desinfección de la semilla es la mejor forma de eliminar o evitar una infección temprana proveniente eventualmente del grano o desde el rastrojo.',
                ],
              },
            ],
            image: control_rincosporiosis_image_2,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Aplicar fungicidas en el momento oportuno',
              },
              {
                type: 'subtitle',
                value:
                  'La cebada es particularmente sensible a las infecciones foliares en estados tempranos de desarrollo.',
              },
              {
                type: 'body',
                value: [
                  'La aplicación foliar de fungicidas tiene su momento oportuno. El realizarlo en estados de desarrollo inadecuados, o con productos de baja eficacia, sólo se traducirá en un mal resultado económico.',
                ],
              },
            ],
            image: control_rincosporiosis_image_3,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: '¿Con qué fungicidas se puede controlar la enfermedad?',
              },
              {
                type: 'body',
                value: [
                  'Consulte con su asesor profesional por el fungicida más adecuado.',
                  'Exija siempre antecedentes de eficacia del fungicida recomendado, principalmente de indicadores o notas de infección, obtenidos por profesionales calificados.',
                  'Eventualmente, deje un pequeño sector del cultivo (lejos de arboledas o bordes de caminos) sin tratar, para verificar la eficacia del producto recomendado.',
                ],
              },
            ],
            image: null,
          },
        ],
        diagnosis: [
          {
            type: 'main',
            title: 'Organismo causal: ',
            causeTitle: 'Hongo',
            subtitle: 'Nombre del hongo: ',
            causeSubtitle: 'Rhynchosporium secalis',
            image: diagnosis_rincosporiosis_main,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Síntomas',
              },
              {
                type: 'body',
                value: [
                  'Los primeros síntomas se detectan desde fines de macolla a inicio de encañado, principalmente en las hojas basales.',
                  'Corresponden a manchas foliares, inicialmente de aspecto acuoso, verde-grisáceas (flechas), con bordes necróticos (café). Las hojas afectadas se van tornando cloróticas (amarillas).',
                ],
              },
            ],
            image: diagnosis_rincosporiosis_image_1,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'En condiciones climáticas de alta humedad relativa, lluvias y temperaturas frías, la enfermedad produce una alta infección foliar, afectando severamente las hojas basales y medias, subiendo rápidamente a las hojas superiores.',
                ],
              },
            ],
            image: diagnosis_rincosporiosis_image_2,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'La enfermedad puede afectar severamente las plantas, secando toda el área foliar, especialmente en siembras tempranas (Julio-Agosto), primaveras con alta humedad relativa, lluvias y temperaturas relativamente frías.',
                ],
              },
            ],
            image: diagnosis_rincosporiosis_image_3,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'Una temprana y severa infección puede producir la tendedura de las plantas, al debilitar las cañas.',
                  'En la fotografía de la derecha se aprecia una parcela con una alta tnededura (a) y otra que fue tratada con fungicida (b).',
                ],
              },
            ],
            image: diagnosis_rincosporiosis_image_4,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'El agente causal',
              },
              {
                type: 'body',
                value: [
                  'La infección puede iniciarse desde la semilla (a) (en Chile es poco frecuente) o desde el rastrojo (b).',
                  'Las lesiones foliares (c) producen gran cantidad de esporas (d) en condiciones de alta humedad relativa, las que son diseminadas por la lluvia a otras hojas y plantas, a través de varios ciclos en la temporada.',
                ],
              },
            ],
            image: diagnosis_rincosporiosis_image_5,
          },
        ],
        prevention: [
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Variedades resistentes',
              },
              {
                type: 'subtitle',
                value:
                  'Elija una variedad resistente o tolerante a la enfermedad.',
              },
              {
                type: 'body',
                value: [
                  'Las variedades resistentes o tolerantes no son afectadas por la enfermedad, o lo son en forma muy leve. Además, no requieren de tratamientos fungicida lo cual significa un menor costo de producción.',
                ],
              },
            ],
            image: prevention_rincosporiosis_image_1,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Manejo de rastrojos',
              },
              {
                type: 'subtitle',
                value: 'El rastrojo debe ser incorporado o destruido.',
              },
              {
                type: 'body',
                value: [
                  'El rastrojo es la primera fuente de infección, puesto que ahí sobrevive el hongo.',
                  'La siembra de cebada sobre rastrojo del mismo cultivo, se traducirá en una infección más temprana y de mayor severidad.',
                ],
              },
            ],
            image: prevention_rincosporiosis_image_2,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Rotación de cultivos',
              },
              {
                type: 'subtitle',
                value:
                  'La rotación de cultivos ayuda a disminuir la severidad de la infección.',
              },
              {
                type: 'body',
                value: [
                  'La rotación de cultivos disminuye en forma significativa la infección, dado que ésta se inicia por esporas del hongo que provienen del rastrojo.',
                ],
              },
            ],
            image: prevention_rincosporiosis_image_3,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Época de siembra',
              },
              {
                type: 'subtitle',
                value:
                  'Atrasar la época de siembra, cuando sea posible, permite escapar muy bien de la enfermedad.',
              },
              {
                type: 'body',
                value: [
                  'Las siembras más afectadas son las de invierno, desde Julio a Agosto.',
                  'Siembras establecidas desde Septiembre en adelante, prácticamente no son afectadas por la Rincosporiosis, excepto en temporadas con primaveras excepcionalmente lluviosas y temperaturas frías.',
                ],
              },
            ],
            image: prevention_rincosporiosis_image_4,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Desinfección de semilla',
              },
              {
                type: 'subtitle',
                value:
                  'Desinfecte la semilla, para eliminar la posible transmisión del hongo o retardar la infección.',
              },
              {
                type: 'body',
                value: [
                  'La desinfección de la semilla es la mejor forma de eliminar o evitar una infección temprana proveniente eventualmente del grano o desde el rastrojo.',
                  'Existen varios desinfectantes de semilla que logran disminuir eficientemente la transmisión de la enfermedad por la semilla y proteger de infecciones tempranas.',
                ],
              },
            ],
            image: prevention_rincosporiosis_image_5,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Aplicar fungicidas en el momento oportuno',
              },
              {
                type: 'subtitle',
                value:
                  'La cebada es particularmente sensible a las infecciones foliares en estados tempranos de desarrollo.',
              },
              {
                type: 'body',
                value: [
                  'La aplicación foliar de fungicidas tiene su momento oportuno.',
                  'El realizarlo en estados de desarrollo inadecuados, o con productos de baja eficacia, sólo se traducirá en un mal resultado económico.',
                  'Emplee sólo fungicidas reconocidamente eficaces. Elija aquellos que posean antecedentes de indicadores de infección (% daño foliar, % plantas afectadas, notas de severidad, etc.). Estos indicadores son mucho más importantes que los de rendimiento o calidad de grano.',
                ],
              },
            ],
            image: prevention_rincosporiosis_image_6,
          },
        ],
      },
    },
    {
      title: 'Mancha en red',
      description:
        'Una enfermedad de creciente importancia en la\n' +
        'producción de cebada, en la zona sur de Chile, es\n' +
        'la mancha en red.',
      dialogTitle: 'Mancha en red o reticulada de la hoja',
      imageCard: mancha_en_red,
      pdf: mancha_en_redPDF,
      video:
        'https://www.youtube.com/watch?v=eOTFNfY5wdE&ab_channel=AgrodelSurEstaci%C3%B3nExperimental',
      moreInfo: {
        control: [
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Variedades resistentes',
              },
              {
                type: 'subtitle',
                value:
                  'Elija una variedad resistente o tolerante a la enfermedad.',
              },
              {
                type: 'body',
                value: [
                  'Las variedades resistentes o tolerantes no son afectadas por la enfermedad, o lo son en forma muy leve. Además, no requieren de tratamientos fungicida lo cual significa un menor costo de producción.',
                  'La variedad Tauro-INIA es una variedad resistente a la mancha reticulada.',
                ],
              },
            ],
            image: control_mancha_image_1,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Desinfección de semilla',
              },
              {
                type: 'subtitle',
                value:
                  'Desinfecte la semilla, para eliminar la posible transmisión del hongo o retardar la infección que se disemina vía aérea desde otros cultivos o rastrojos.',
              },
              {
                type: 'body',
                value: [
                  'Sin embargo, hay escasos desinfectantes de semilla que logran disminuir la transmisión de la enfermedad por la semilla.',
                ],
              },
            ],
            image: control_mancha_image_2,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Aplicar fungicidas en el momento oportuno',
              },
              {
                type: 'subtitle',
                value:
                  'La cebada es particularmente sensible a las infecciones foliares en estados tempranos de desarrollo.',
              },
              {
                type: 'body',
                value: [
                  'La primera aplicación foliar de fungicidas debe realizarse entre fines de macolla e inicio de encañado, si se ha detectado infección foliar.',
                  'No debe postergar el tratamiento puesto que no hay a la fecha fungicidas con un alto grado de eficacia en disminuir la infección.',
                ],
              },
            ],
            image: control_mancha_image_3,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value:
                  '¿Con qué fungicidas se puede controlar la mancha reticulada?',
              },
              {
                type: 'body',
                value: [
                  'Consulte con su asesor profesional por el fungicida más adecuado.',
                  'Exija siempre antecedentes de eficacia del fungicida recomendado, principalmente de indicadores o notas de infección, obtenidos por profesionales calificados.',
                  'Eventualmente, deje un pequeño sector del cultivo (lejos de arboledas o bordes de caminos) sin tratar, para verificar la eficacia del producto recomendado.',
                ],
              },
            ],
            image: null,
          },
        ],
        diagnosis: [
          {
            type: 'main',
            title: 'Organismo causal: ',
            causeTitle: 'Hongo',
            subtitle: 'Nombre del hongo: ',
            causeSubtitle:
              'Pyrenophora teres (Sin.: Drechslera [Helminthosporium] teres)',
            image: diagnosis_mancha_main,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Síntomas',
              },
              {
                type: 'body',
                value: [
                  'Los primeros síntomas se detectan al estado de 2 hojas, cuando la infección proviene de la semilla.',
                  'Corresponden a manchas necróticas (café) en las hojas, reticuladas (aspecto de red o ramificadas), alargadas, con clorosis (amarillez) alrededor de las manchas.',
                ],
              },
            ],
            image: diagnosis_mancha_image_1,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'A medida que las plantas se desarrollan y, producto de la lignificación de los haces vasculares, las manchas adoptan una morfología alargada manteniendo una apariencia de red (fotos centrales).',
                ],
              },
            ],
            image: diagnosis_mancha_image_2,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'La enfermedad puede afectar severamente las plantas, secando toda el área foliar, especialmente en primaveras con alta humedad relativa y temperaturas cálidas.',
                ],
              },
            ],
            image: diagnosis_mancha_image_3,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'El agente causal',
              },
              {
                type: 'body',
                value: [
                  'En condiciones de alta humedad, las manchas foliares (a) producen abundantes esporas del hongo patógeno (b, c y d), las cuales por acción del viento, se diseminan a otras hojas y plantas iniciando nuevas infecciones.',
                ],
              },
            ],
            image: diagnosis_mancha_image_4,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'El hongo patógeno permanece en el rastrojo de una temporada a otra como pseudotecios (a y b), estructuras reproductivas de origen sexuado.',
                  'Durante la primavera siguiente, los pseudotecios liberan las ascosporas (c), las que transportadas por el viento, inician nuevos ciclos de infección.',
                ],
              },
            ],
            image: diagnosis_mancha_image_5,
          },
        ],
        prevention: [
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Variedades resistentes',
              },
              {
                type: 'subtitle',
                value:
                  'Elija una variedad resistente o tolerante a la enfermedad.',
              },
              {
                type: 'body',
                value: [
                  'Las variedades resistentes o tolerantes no son afectadas por la enfermedad, o lo son en forma muy leve.',
                  'Además, no requieren de tratamientos fungicida lo cual significa un menor costo de producción.',
                ],
              },
            ],
            image: prevention_mancha_image_1,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Manejo de rastrojos',
              },
              {
                type: 'subtitle',
                value: 'El rastrojo debe ser incorporado o destruido.',
              },
              {
                type: 'body',
                value: [
                  'El rastrojo es la primera fuente de infección, puesto que ahí sobrevive el hongo.',
                  'La siembra de cebada sobre rastrojo del mismo cultivo, se traducirá en una infección más temprana y de mayor severidad.',
                ],
              },
            ],
            image: prevention_mancha_image_2,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Rotación de cultivos',
              },
              {
                type: 'subtitle',
                value:
                  'La rotación de cultivos ayuda a disminuir la severidad de la infección.',
              },
              {
                type: 'body',
                value: [
                  'La rotación de cultivos no evita la infección, dado que ésta se inicia por esporas del hongo que provienen desde otros cultivos o rastrojos cercanos, por vía aérea. Pero, contribuye a disminuir el nivel de ataque.',
                ],
              },
            ],
            image: prevention_mancha_image_3,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Desinfección de semilla',
              },
              {
                type: 'subtitle',
                value:
                  'Desinfecte la semilla, para eliminar la posible transmisión del hongo o retardar la infección.',
              },
              {
                type: 'body',
                value: [
                  'La desinfección de la semilla es la mejor forma de eliminar o evitar una infección temprana proveniente del grano o desde otros cultivos y rastrojo.',
                  'Sin embargo, hay escasos desinfectantes de semilla que logran disminuir la transmisión de la enfermedad por la semilla.',
                ],
              },
            ],
            image: prevention_mancha_image_4,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Aplicar fungicidas en el momento oportuno',
              },
              {
                type: 'subtitle',
                value:
                  'La cebada es particularmente sensible a las infecciones foliares en estados tempranos de desarrollo.',
              },
              {
                type: 'body',
                value: [
                  'La aplicación foliar de fungicidas tiene su momento oportuno. El realizarlo en estados de desarrollo inadecuados, o con productos de baja eficacia, sólo se traducirá en un mal resultado económico.',
                  'Emplee sólo fungicidas reconocidamente eficaces. Elija aquellos que posean antecedentes de indicadores de infección (% daño foliar, % plantas afectadas, notas de severidad, etc.). Estos indicadores son mucho más importantes que los de rendimiento o calidad de grano.',
                ],
              },
            ],
            image: prevention_mancha_image_5,
          },
        ],
      },
    },
  ],
};

export default cebada;
