import septoriosis from '../../../resources/learning/trigo/septoriosis.jpeg';
import septoriosisPDF from '../../../resources/learning/trigo/septoriosis.pdf';
import roya_amarilla from '../../../resources/learning/trigo/roya_amarilla.jpeg';
import roya_amarillaPDF from '../../../resources/learning/trigo/roya_amarilla.pdf';
import mancha_ocular from '../../../resources/learning/trigo/mancha_ocular.jpeg';
import mancha_ocularPDF from '../../../resources/learning/trigo/mancha_ocular.pdf';
import pudricion_radical from '../../../resources/learning/trigo/pudricion_radical.jpeg';
import pudricion_radicalPDF from '../../../resources/learning/trigo/pudricion_radical.pdf';

// Control - Septorosis
import control_septorosis_image_1 from '../../../resources/learning/trigo/septorosis/control/image_1.jpeg';
import control_septorosis_image_2 from '../../../resources/learning/trigo/septorosis/control/image_2.jpeg';
import control_septorosis_image_3 from '../../../resources/learning/trigo/septorosis/control/image_3.jpeg';
import control_septorosis_image_4 from '../../../resources/learning/trigo/septorosis/control/image_4.jpeg';

// Diagnosis - Septoriosis
import diagnosis_septoriosis_main from '../../../resources/learning/trigo/septorosis/diagnosis/main.jpeg';
import diagnosis_septorosis_image_1 from '../../../resources/learning/trigo/septorosis/diagnosis/image_1.jpeg';
import diagnosis_septorosis_image_2 from '../../../resources/learning/trigo/septorosis/diagnosis/image_2.jpeg';
import diagnosis_septorosis_image_3 from '../../../resources/learning/trigo/septorosis/diagnosis/image_3.jpeg';
import diagnosis_septorosis_image_4 from '../../../resources/learning/trigo/septorosis/diagnosis/image_4.jpeg';
import diagnosis_septorosis_image_5 from '../../../resources/learning/trigo/septorosis/diagnosis/image_5.jpeg';
import diagnosis_septorosis_image_6 from '../../../resources/learning/trigo/septorosis/diagnosis/image_6.jpeg';
import diagnosis_septorosis_image_7 from '../../../resources/learning/trigo/septorosis/diagnosis/image_7.jpeg';

// Prevention - Septoriosis
import prevention_septoriosis_image_1 from '../../../resources/learning/trigo/septorosis/prevention/image_1.jpeg';
import prevention_septoriosis_image_2 from '../../../resources/learning/trigo/septorosis/prevention/image_2.jpeg';
import prevention_septoriosis_image_3 from '../../../resources/learning/trigo/septorosis/prevention/image_3.jpeg';
import prevention_septoriosis_image_4 from '../../../resources/learning/trigo/septorosis/prevention/image_4.jpeg';
import prevention_septoriosis_image_5 from '../../../resources/learning/trigo/septorosis/prevention/image_5.jpeg';
import prevention_septoriosis_image_6 from '../../../resources/learning/trigo/septorosis/prevention/image_6.jpeg';

// Control - Roya amarilla
import control_roya_image_1 from '../../../resources/learning/trigo/roya_amarilla/control/image_1.jpeg';
import control_roya_image_2 from '../../../resources/learning/trigo/roya_amarilla/control/image_2.jpeg';
import control_roya_image_3 from '../../../resources/learning/trigo/roya_amarilla/control/image_3.jpeg';

// Diagnosis - Roya amarilla
import diagnosis_roya_main from '../../../resources/learning/trigo/roya_amarilla/diagnosis/main.jpeg';
import diagnosis_roya_image_1 from '../../../resources/learning/trigo/roya_amarilla/diagnosis/image_1.jpeg';
import diagnosis_roya_image_2 from '../../../resources/learning/trigo/roya_amarilla/diagnosis/image_2.jpeg';
import diagnosis_roya_image_3 from '../../../resources/learning/trigo/roya_amarilla/diagnosis/image_3.jpeg';
import diagnosis_roya_image_4 from '../../../resources/learning/trigo/roya_amarilla/diagnosis/image_4.jpeg';

// Prevention - Roya amarilla
import prevention_roya_image_1 from '../../../resources/learning/trigo/roya_amarilla/prevention/image_1.jpeg';
import prevention_roya_image_2 from '../../../resources/learning/trigo/roya_amarilla/prevention/image_2.jpeg';
import prevention_roya_image_3 from '../../../resources/learning/trigo/roya_amarilla/prevention/image_3.jpeg';
import prevention_roya_image_4 from '../../../resources/learning/trigo/roya_amarilla/prevention/image_4.jpeg';

// Control - Mancha ocular
import control_mancha_image_1 from '../../../resources/learning/trigo/mancha_ocular/control/image_1.jpeg';
import control_mancha_image_2 from '../../../resources/learning/trigo/mancha_ocular/control/image_2.jpeg';
import control_mancha_image_3 from '../../../resources/learning/trigo/mancha_ocular/control/image_3.jpeg';
import control_mancha_image_4 from '../../../resources/learning/trigo/mancha_ocular/control/image_4.jpeg';

// Diagnosis - Mancha ocular
import diagnosis_mancha_main from '../../../resources/learning/trigo/mancha_ocular/diagnosis/maing.jpeg';
import diagnosis_mancha_image_1 from '../../../resources/learning/trigo/mancha_ocular/diagnosis/image_1.jpeg';
import diagnosis_mancha_image_2 from '../../../resources/learning/trigo/mancha_ocular/diagnosis/image_2.jpeg';
import diagnosis_mancha_image_3 from '../../../resources/learning/trigo/mancha_ocular/diagnosis/image_3.jpeg';
import diagnosis_mancha_image_4 from '../../../resources/learning/trigo/mancha_ocular/diagnosis/image_4.jpeg';
import diagnosis_mancha_image_5 from '../../../resources/learning/trigo/mancha_ocular/diagnosis/image_5.jpeg';
import diagnosis_mancha_image_6 from '../../../resources/learning/trigo/mancha_ocular/diagnosis/iamge_6.jpeg';
import diagnosis_mancha_image_7 from '../../../resources/learning/trigo/mancha_ocular/diagnosis/image_7.jpeg';
import diagnosis_mancha_image_8 from '../../../resources/learning/trigo/mancha_ocular/diagnosis/image_8.jpeg';
import diagnosis_mancha_image_9 from '../../../resources/learning/trigo/mancha_ocular/diagnosis/image_9.jpeg';
import diagnosis_mancha_image_10 from '../../../resources/learning/trigo/mancha_ocular/diagnosis/image_10.jpeg';

// Prevention - Mancha ocular
import prevention_mancha_image_1 from '../../../resources/learning/trigo/mancha_ocular/prevention/image_1.jpeg';
import prevention_mancha_image_2 from '../../../resources/learning/trigo/mancha_ocular/prevention/image_2.jpeg';
import prevention_mancha_image_3 from '../../../resources/learning/trigo/mancha_ocular/prevention/image_3.jpeg';
import prevention_mancha_image_4 from '../../../resources/learning/trigo/mancha_ocular/prevention/image_4.jpeg';
import prevention_mancha_image_5 from '../../../resources/learning/trigo/mancha_ocular/prevention/image_5.jpeg';
import prevention_mancha_image_6 from '../../../resources/learning/trigo/mancha_ocular/prevention/image_6.jpeg';
import prevention_mancha_image_7 from '../../../resources/learning/trigo/mancha_ocular/prevention/image_7.jpeg';
import prevention_mancha_image_8 from '../../../resources/learning/trigo/mancha_ocular/prevention/image_8.jpeg';
import prevention_mancha_image_9 from '../../../resources/learning/trigo/mancha_ocular/prevention/image_9.jpeg';
import prevention_mancha_image_10 from '../../../resources/learning/trigo/mancha_ocular/prevention/image_10.jpeg';

// Control - Mal del pie
import control_pudricion_image_1 from '../../../resources/learning/trigo/pudricion_radical/control/image_1.jpeg';
import control_pudricion_image_2 from '../../../resources/learning/trigo/pudricion_radical/control/image_2.jpeg';
import control_pudricion_image_3 from '../../../resources/learning/trigo/pudricion_radical/control/image_3.jpeg';
import control_pudricion_image_4 from '../../../resources/learning/trigo/pudricion_radical/control/image_4.jpeg';
import control_pudricion_image_5 from '../../../resources/learning/trigo/pudricion_radical/control/image_5.jpeg';

// Diagnosis - Mal del pie
import diagnosis_pudricion_main from '../../../resources/learning/trigo/pudricion_radical/diagnosis/main.jpeg';
import diagnosis_pudricion_image_1 from '../../../resources/learning/trigo/pudricion_radical/diagnosis/image_1.jpeg';
import diagnosis_pudricion_image_2 from '../../../resources/learning/trigo/pudricion_radical/diagnosis/image_2.jpeg';
import diagnosis_pudricion_image_3 from '../../../resources/learning/trigo/pudricion_radical/diagnosis/image_3.jpeg';
import diagnosis_pudricion_image_4 from '../../../resources/learning/trigo/pudricion_radical/diagnosis/image_4.jpeg';
import diagnosis_pudricion_image_5 from '../../../resources/learning/trigo/pudricion_radical/diagnosis/image_5.jpeg';
import diagnosis_pudricion_image_6 from '../../../resources/learning/trigo/pudricion_radical/diagnosis/image_6.jpeg';
import diagnosis_pudricion_image_7 from '../../../resources/learning/trigo/pudricion_radical/diagnosis/image_7.jpeg';
import diagnosis_pudricion_image_8 from '../../../resources/learning/trigo/pudricion_radical/diagnosis/image_8.jpeg';
import diagnosis_pudricion_image_9 from '../../../resources/learning/trigo/pudricion_radical/diagnosis/image_9.jpeg';
import diagnosis_pudricion_image_10 from '../../../resources/learning/trigo/pudricion_radical/diagnosis/image_10.jpeg';

// Prevention - Mal del pie
import prevention_pudricion_image_1 from '../../../resources/learning/trigo/pudricion_radical/prevention/image_1.jpeg';
import prevention_pudricion_image_2 from '../../../resources/learning/trigo/pudricion_radical/prevention/image_2.jpeg';
import prevention_pudricion_image_3 from '../../../resources/learning/trigo/pudricion_radical/prevention/image_3.jpeg';
import prevention_pudricion_image_4 from '../../../resources/learning/trigo/pudricion_radical/prevention/image_4.jpeg';
import prevention_pudricion_image_5 from '../../../resources/learning/trigo/pudricion_radical/prevention/image_5.jpeg';
import prevention_pudricion_image_6 from '../../../resources/learning/trigo/pudricion_radical/prevention/image_6.jpeg';
import prevention_pudricion_image_7 from '../../../resources/learning/trigo/pudricion_radical/prevention/image_7.jpeg';

const trigo = {
  title: 'Trigo',
  cards: [
    {
      title: 'La septoriosis',
      description:
        'La septoriosis de la hoja del trigo, también\n' +
        'denominada mancha foliar, es una enfermedad\n' +
        'endémica en las zonas centro-sur y sur de Chile.',
      dialogTitle: 'La septoriosis o mancha de la hoja del trigo',
      imageCard: septoriosis,
      pdf: septoriosisPDF,
      video:
        'https://www.youtube.com/watch?v=5NteLrAtq5c&ab_channel=AgrodelSurEstaci%C3%B3nExperimental',
      moreInfo: {
        control: [
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Variedades tolerantes',
              },
              {
                type: 'subtitle',
                value:
                  'Elija una variedad tolerante a la enfermedad y siémbrela en la época recomendada.',
              },
              {
                type: 'body',
                value: [
                  'Las variedades de ciclo más corto, son más susceptibles a la infección, especialmente si se siembran más temprano de lo recomendado.',
                ],
              },
            ],
            image: control_septorosis_image_1,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Aplicación foliar de fungicida',
              },
              {
                type: 'body',
                value: [
                  'El empleo oportuno de un buen fungicida foliar controla efectivamente la infección.',
                  'La aplicación de fungicidas se recomienda para siembras establecidas desde fines de Abril hasta fines de Junio, en temporadas con alta pluviometría de Septiembre y Octubre y, en zonas donde la enfermedad se presenta todos los años.',
                ],
              },
            ],
            image: control_septorosis_image_2,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: '¿Con qué fungicidas controlo la septoriosis?',
              },
              {
                type: 'body',
                value: [
                  'Consulte con su asesor profesional por el fungicida más adecuado.',
                  'Exija siempre antecedentes de eficacia del fungicida recomendado, principalmente de indicadores o notas de infección, obtenidos por profesionales calificados.',
                  'Eventualmente, deje un pequeño sector del cultivo (lejos de arboledas o bordes de caminos) sin tratar, para verificar la eficacia del producto recomendado.',
                ],
              },
            ],
            image: null,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: '¿Cuándo se realizan las aplicaciones foliares?',
              },
              {
                type: 'body',
                value: [
                  'La 1ª aplicación foliar de fungicida para controlar la septoriosis de la hoja del trigo debe realizarse al estado de 1er. nudo en la zona que comprende Victoria-Traiguén al norte y, al estado de 2º nudo para la zona de Victoria al sur.',
                  'La razón es evitar que la enfermedad alcance las hojas superiores y realizar no más de 2 aplicaciones en la temporada.',
                  'Un 2º tratamiento se requerirá a los 40 días, principalmente en la zona de Victoria al sur, en el caso de siembras de Abril a Junio y en temporadas de primavera lluviosa (> 100 mm en septiembre, y/o > 230 mm entre Octubre y Noviembre).',
                ],
              },
            ],
            image: control_septorosis_image_3,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'Los tratamientos foliares tanto en el estado de macolla como previo a la espigadura, son de mayor riesgo o poco efectivos.',
                  'Un tratamiento muy temprano puede requerir un mayor número de aplicaciones en temporadas lluviosas.',
                  'Una aplicación tardía no evitará la infección de las dos hojas superiores.',
                  'Por cada 10% de infección con septoriosis en las dos hojas superiores, la planta disminuirá su rendimiento en un 5%.',
                ],
              },
            ],
            image: control_septorosis_image_4,
          },
        ],
        diagnosis: [
          {
            type: 'main',
            title: 'Organismo causal: ',
            causeTitle: 'Hongo',
            subtitle: 'Nombre del hongo: ',
            causeSubtitle:
              'Mycosphaerella graminicola (Sin.: Septoria tritici)',
            image: diagnosis_septoriosis_main,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Síntomas en plantulas',
              },
              {
                type: 'body',
                value: [
                  'Lesiones de septoriosis en estados de desarrollo temprano de las plantas (flecha). En el recuadro un aumento de esas mismas lesiones. (Imagen 1)',
                  'Las lesiones o manchas presentan puntos negros en el centro (indicados por la flecha en la imagen 2).',
                ],
              },
            ],
            image: diagnosis_septorosis_image_1,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value:
                  'CLAVE: La clave importante en el diagnóstico es la presencia de puntos negros (picnidios) en el centro de las lesiones.',
              },
            ],
            image: null,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Síntomas en el periodo de encañado',
              },
              {
                type: 'body',
                value: [
                  'En este estado de desarrollo ya aparecen hojas basales con fuerte infección, algunas completamente secas (Imagen 1)',
                  'Comienzan a aparecer lesiones en hojas superiores (Imagen 2).',
                ],
              },
            ],
            image: diagnosis_septorosis_image_2,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value:
                  'CLAVE: La clave en el diagnóstico sigue siendo la presencia de puntos negros (picnidios) en las hojas secas o en el centro de las lesiones.',
              },
            ],
            image: null,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Síntomas en el periodo de encañado',
              },
              {
                type: 'body',
                value: [
                  'La enfermedad comienza a afectar fuertemente hojas superiores, en temporadas de alta pluviometría primaveral.',
                ],
              },
            ],
            image: diagnosis_septorosis_image_3,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'El agente causal',
              },
              {
                type: 'body',
                value: [
                  'A.- Síntomas en una hoja de planta adulta,con la presencia de clorosis (amarillez) y picnidios.',
                  'B.- Picnidios de S. tritici sobre una lesión foliar.',
                  'C.- Liberación de las esporas que se encuentran en el interior de los picnidios, producto de las lluvias o la alta humedad.',
                ],
              },
            ],
            image: diagnosis_septorosis_image_4,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'El agente causal',
              },
              {
                type: 'body',
                value: [
                  'A.- Esporas que están siendo liberadas desde el interior de los picnidios.',
                  'B.- Picnidiosporas vistas al microscopio.',
                  'C.- Síntomas de una infección severa en una hoja de trigo.',
                ],
              },
            ],
            image: diagnosis_septorosis_image_5,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Síntomas en plantas adultas',
              },
              {
                type: 'body',
                value: [
                  'Manchas foliares típicas causadas por la septoriosis de la hoja en plantas de trigo.',
                ],
              },
            ],
            image: diagnosis_septorosis_image_6,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Síntomas en plantas maduras',
              },
              {
                type: 'body',
                value: [
                  'Síntomas en una hoja bandera de trigo con alta infección.',
                ],
              },
            ],
            image: diagnosis_septorosis_image_7,
          },
        ],
        prevention: [
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Época de siembra',
              },
              {
                type: 'subtitle',
                value:
                  'Atrasar la época de siembra, cuando sea posible, permite escapar muy bien de la enfermedad.',
              },
              {
                type: 'body',
                value: [
                  'Las siembras más afectadas son las desde fines de Abril a fines de Junio.',
                  'Siembras establecidas desde Julio en adelante, prácticamente no son afectadas por la septoriosis, excepto en temporadas con primaveras excepcionalmente lluviosas.',
                ],
              },
            ],
            image: prevention_septoriosis_image_1,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Variedades tolerantes',
              },
              {
                type: 'subtitle',
                value:
                  'Elija una variedad tolerante a la enfermedad y siémbrela en la época recomendada.',
              },
              {
                type: 'body',
                value: [
                  'Las variedades de ciclo más corto, son más susceptibles a la infección, especialmente si se siembran más temprano de lo recomendado.',
                ],
              },
            ],
            image: prevention_septoriosis_image_2,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Manejo de rastrojos',
              },
              {
                type: 'subtitle',
                value: 'El rastrojo debe ser incorporado o destruido.',
              },
              {
                type: 'body',
                value: [
                  'El rastrojo es la primera fuente de infección, puesto que ahí sobrevive el hongo.',
                  'La siembra de trigo sobre rastrojo del mismo cultivo, se traducirá en una infección más temprana y de mayor severidad.',
                ],
              },
            ],
            image: prevention_septoriosis_image_3,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Rotación de cultivos',
              },
              {
                type: 'subtitle',
                value:
                  'La rotación de cultivos ayuda a disminuir la severidad de la infección.',
              },
              {
                type: 'body',
                value: [
                  'La rotación de cultivos no evita la infección, dado que ésta se inicia por esporas del hongo que provienen desde otros cultivos cercanos, por vía aérea. Pero, contribuye a disminuir el nivel de ataque.',
                ],
              },
            ],
            image: prevention_septoriosis_image_4,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Eliminar plantas voluntarias de trigo',
              },
              {
                type: 'subtitle',
                value:
                  'Las plantas voluntarias de trigo provenientes de la semilla caída durante la cosecha anterior, suelen estar temprana y fuertemente infectadas.',
              },
              {
                type: 'body',
                value: [
                  'Estas plantas deben eliminarse en estados muy tempranos o evitar que germinen.',
                  'La aplicación de un herbicida en plantas voluntarias ya desarrolladas, no es efectivo puesto que no logra evitar que infecten a las nuevas plantas.',
                ],
              },
            ],
            image: prevention_septoriosis_image_5,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Aplicar fungicidas en el momento oportuno',
              },
              {
                type: 'body',
                value: [
                  'La aplicación foliar de fungicidas tiene su momento oportuno.',
                  'El realizarlo en estados de desarrollo inadecuados, o con productos de baja eficacia, sólo se traducirá en un mal resultado económico.',
                  'Emplee sólo fungicidas reconocidamente eficaces. Elija aquellos que posean antecedentes de indicadores de infección (% daño foliar, % plantas afectadas, notas de severidad, etc.). Estos indicadores son mucho más importantes que los de rendimiento o calidad de grano.',
                ],
              },
            ],
            image: prevention_septoriosis_image_6,
          },
        ],
      },
    },
    {
      title: 'Roya amarilla',
      description:
        'La roya amarilla o polvillo estriado es una de las enfermedades importantes que afectan al trigo. Se presenta prácticamente en toda la zona triguera...',
      dialogTitle: 'Roya amarilla o polvillo estriado del trigo',
      imageCard: roya_amarilla,
      pdf: roya_amarillaPDF,
      video:
        'https://www.youtube.com/watch?v=XQxm04_aQYI&ab_channel=AgrodelSurEstaci%C3%B3nExperimental',
      moreInfo: {
        control: [
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Variedades tolerantes',
              },
              {
                type: 'subtitle',
                value: 'Elija una variedad resistente.',
              },
              {
                type: 'body',
                value: [
                  'En el caso del polvillo estriado, la mejor alternativa es el empleo de una variedad resistente. Las variedades resistentes no son afectadas por la enfermedad. Además, no requieren de tratamientos fungicida lo cual significa un menor costo de producción.',
                ],
              },
            ],
            image: control_roya_image_1,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Desinfección de semilla',
              },
              {
                type: 'subtitle',
                value: 'Desinfecte la semilla para retardar la infección.',
              },
              {
                type: 'body',
                value: [
                  'La desinfección de la semilla es una alternativa para retardar una infección temprana.',
                  'Sin embargo, es mucho más efectiva en siembras efectuadas desde el mes de Agosto en adelante.',
                ],
              },
            ],
            image: control_roya_image_2,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Aplicar fungicida en el momento oportuno',
              },
              {
                type: 'subtitle',
                value:
                  'La roya amarilla debe ser controlada inmediatamente de detectadas las primeras pústulas, en variedades susceptibles.\n',
              },
              {
                type: 'body',
                value: [
                  'Recuerde que la aplicación foliar de fungicidas tiene su momento oportuno. El realizarlo en estados de desarrollo inadecuados, o con productos de baja eficacia, sólo se traducirá en un mal resultado económico.',
                  'A sólo 10 días de detectadas las primeras pústulas, el polvillo estriado ya ha afectado sobre un 12% el rendimiento en variedades susceptibles.',
                ],
              },
            ],
            image: control_roya_image_3,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: '¿Con qué fungicidas controlo la roya amarilla?',
              },
              {
                type: 'body',
                value: [
                  'Consulte con su asesor profesional por el fungicida más adecuado.',
                  'Exija siempre antecedentes de eficacia del fungicida recomendado, principalmente de indicadores o notas de infección, obtenidos por profesionales calificados.',
                  'Eventualmente, deje un pequeño sector del cultivo (lejos de arboledas o bordes de caminos) sin tratar, para verificar la eficacia del producto recomendado.',
                ],
              },
            ],
            image: null,
          },
        ],
        diagnosis: [
          {
            type: 'main',
            title: 'Organismo causal: ',
            causeTitle: 'Hongo',
            subtitle: 'Nombre del hongo: ',
            causeSubtitle: 'Puccinia striiformis f. sp. tritici',
            image: diagnosis_roya_main,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Síntomas',
              },
              {
                type: 'body',
                value: [
                  'Los primeros síntomas se detectan, normalmente, en las hojas medias y basales. Corresponden a estrías conformadas por numerosas pústulas con abundante polvillo anaranjado a amarillo sobre éstas.',
                ],
              },
            ],
            image: diagnosis_roya_image_1,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'En pocos días (6-10), la enfermedad puede afectar a gran parte del área de la hoja y aparece igualmente en nuevas hojas y muchas otras plantas cercanas.',
                ],
              },
            ],
            image: diagnosis_roya_image_2,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'En variedades susceptibles puede infectar incluso las espiguillas y barbas, lo cual aumenta significativamente las pérdidas de rendimiento y calidad.',
                ],
              },
            ],
            image: diagnosis_roya_image_3,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'Las pústulas corresponden a la erupción de las estructuras reproductivas del hongo, a través de la epidermis de la hoja (fotos izquierda y central), lo cual causa una alta deshidratación de las plantas.',
                  'El polvillo sobre las pústulas corresponden a las esporas del hongo, o uredosporas (foto derecha).',
                ],
              },
            ],
            image: diagnosis_roya_image_4,
          },
        ],
        prevention: [
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Variedades resistentes',
              },
              {
                type: 'subtitle',
                value:
                  'La mejor alternativa de prevención a la roya amarilla del trigo es la elección de una variedad resistente.',
              },
              {
                type: 'body',
                value: [
                  'La mayoría de las variedades comerciales de trigo que se venden en el país, tanto de hábito invernal como alternativo y primaveral, son resistentes a la enfermedad. Por tanto no hay razones para sembrar variedades susceptibles.',
                ],
              },
            ],
            image: prevention_roya_image_1,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'No exceda la fertilización nitrogenada',
              },
              {
                type: 'subtitle',
                value:
                  'Un exceso de fertilización nitrogenada es anti económico y contra producente. Fertilice de acuerdo al análisis de suelo y a lo que la planta necesita.',
              },
              {
                type: 'body',
                value: [
                  'El exceso de nitrógeno retarda la lignificación y madurez de los tejidos, favorece una mayor masa vegetativa, aumentando así la humedad relativa. Por tanto, predispone a una mayor severidad de la enfermedad.',
                ],
              },
            ],
            image: prevention_roya_image_2,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Desinfección de semilla',
              },
              {
                type: 'subtitle',
                value:
                  'Algunos desinfectantes de semilla logran retardar el inicio de la infección.',
              },
              {
                type: 'body',
                value: [
                  'A través del empleo de algunos desinfectantes de semilla sistémicos y de largo efecto residual, se logra proteger a las plantas durante los primeros estados de desarrollo y retardar el inicio de la infección.',
                  'Esta alternativa es eficaz principalmente para siembras de primavera o en zonas en que la infección suele aparecer antes de los 80 días contados desde la siembra.',
                ],
              },
            ],
            image: prevention_roya_image_3,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Aplicar fungicidas en el momento oportuno',
              },
              {
                type: 'body',
                value: [
                  'La aplicación foliar de fungicidas tiene su momento oportuno, y en el caso del polvillo estriado, ese momento en crítico.',
                  'La aplicación de fungicida debe realizarse inmediatamente de detectarse las primeras plantas con síntomas, independientemente del estado de desarrollo de las plantas. Especialmente si las condiciones climáticas son de temperaturas moderadas a frías y escasas lluvias.',
                  'Emplee sólo fungicidas reconocidamente eficaces. Elija aquellos que posean antecedentes de indicadores de infección (% daño foliar, % plantas afectadas, notas de severidad, etc.). Estos indicadores son mucho más importantes que los de rendimiento o calidad de grano.',
                ],
              },
            ],
            image: prevention_roya_image_4,
          },
        ],
      },
    },
    {
      title: 'La mancha ocular del trigo',
      description:
        'La Mancha Ocular del Trigo, se detectó en Chile recién el año 1994, en diferentes áreas de la zona sur, especialmente en suelos rojos arcillosos y...',
      dialogTitle: 'Mancha ocular del trigo',
      imageCard: mancha_ocular,
      pdf: mancha_ocularPDF,
      video:
        'https://www.youtube.com/watch?v=1klcOczGSEs&ab_channel=AgrodelSurEstaci%C3%B3nExperimental',
      moreInfo: {
        control: [
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Disminuya la altura de las plantas',
              },
              {
                type: 'subtitle',
                value:
                  'Aplique un regulador de crecimiento para reducir la altura de las plantas. Logrará disminuir la tendedura en caso de infección severa.',
              },
              {
                type: 'body',
                value: [
                  'La mayor pérdida por la mancha ocular ocurre cuando las plantas se tienden por efecto de la pudrición de los cuellos.',
                  'Al reducir la altura de las plantas, aquellas que están sanas o con baja infección soportan en mejor forma el peso de las plantas infectadas y se evita las tendeduras masivas.',
                ],
              },
            ],
            image: control_mancha_image_1,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Aplicación foliar de fungicida',
              },
              {
                type: 'subtitle',
                value:
                  'El empleo oportuno de un buen fungicida foliar controla efectivamente la infección, con una sola aplicación.',
              },
              {
                type: 'body',
                value: [
                  'La aplicación de fungicidas se recomienda sólo para siembras de otoño - invierno, en zonas con historial de mancha ocular.',
                  'La aplicación es de tipo preventivo pues no hay otra alternativa.',
                ],
              },
            ],
            image: control_mancha_image_2,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: '¿Con qué fungicidas controlo la mancha ocular?',
              },
              {
                type: 'body',
                value: [
                  'Consulte con su asesor profesional por el fungicida más adecuado.',
                  'Exija siempre antecedentes de eficacia del fungicida recomendado, principalmente de indicadores o notas de infección, obtenidos por profesionales calificados.',
                  'Eventualmente, deje un pequeño sector del cultivo (lejos de arboledas o bordes de caminos) sin tratar, para verificar la eficacia del producto recomendado.',
                ],
              },
            ],
            image: null,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: '¿Cuándo se realiza la aplicación foliar?',
              },
              {
                type: 'body',
                value: [
                  'La única aplicación foliar de fungicida para controlar la mancha ocular del trigo debe realizarse al estado de fines de macolla – a 1er. nudo, como máximo. Se debe aplicar antes del cierre de la hilera.',
                  'La razón es que el fungicida debe llegar a la zona del cuello de las plantas, por tanto aplicaciones en plantas con mayor desarrollo pierden eficacia por la interferencia física de las hojas a la llegada del fungicida a la zona del cuello.',
                ],
              },
            ],
            image: control_mancha_image_3,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'Los tratamientos foliares posteriores a inicio de encañado son de baja eficacia.',
                  'Tratamientos realizados a la detección de síntomas, esto es al 4º nudo aproximadamente, son absolutamente inefectivos.',
                  'Emplee sólo fungicidas que le garanticen un buen Control. Elija aquellos que tengan probada eficacia, basada en indicadores de infección y no sólo en rendimiento o calidad.',
                ],
              },
            ],
            image: control_mancha_image_4,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: '¡No malgaste su dinero ni arriesgue su producción!',
              },
            ],
            image: null,
          },
        ],
        diagnosis: [
          {
            type: 'main',
            title: 'Organismo causal: ',
            causeTitle: 'Hongo',
            subtitle: 'Nombre del hongo: ',
            causeSubtitle:
              'Oculimacula [Tapesia] yallundae (Anam.: Helgardia [Pseudocercosporella] herpotrichoides)',
            image: diagnosis_mancha_main,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Síntomas',
              },
              {
                type: 'body',
                value: [
                  'Manchones de plantas con tendedura (Imagen 1).',
                  'Plantas quedan completamente postradas (Imagen 2).',
                ],
              },
            ],
            image: diagnosis_mancha_image_1,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'Abundante presencia de espigas blancas, previo y posterior a la tendedura.',
                ],
              },
            ],
            image: diagnosis_mancha_image_2,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'Plantas se quiebran en la base, a pocos cm sobre el suelo.',
                ],
              },
            ],
            image: diagnosis_mancha_image_3,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'Síntomas claves: manchas ovaladas, de bordes difusos en el 1er. entrenudo, a veces en el 2º entrenudo, con pequeños puntos negros al centro de las lesiones.',
                ],
              },
            ],
            image: diagnosis_mancha_image_4,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Clave para el diagnóstico',
              },
              {
                type: 'body',
                value: [
                  'La mancha ocular (foto izquierda) es ovalada y de contornos difusos (flechas), y no permiten trazar una línea definida entre tejido sano y enfermo.',
                  'Lo anterior, para no confundirla con otra enfermedad causada por Rhizoctonia (foto derecha, flechas naranjas), cuyos contornos son muy nítidos y con micelio blanco sobre las lesiones.',
                ],
              },
            ],
            image: diagnosis_mancha_image_5,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Síntomas',
              },
              {
                type: 'body',
                value: [
                  'Síntomas hacia la madurez del cultivo, con manchas de pequeño tamaño (flecha amarilla) y otras circundando todo el contorno de la caña (flechas negras).',
                  'También se observa zonas de quiebre de las cañas (flechas rojas) por la severa pudrición del cuello.',
                ],
              },
            ],
            image: diagnosis_mancha_image_6,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'Los puntos negros en el centro de las manchas oculares, corresponden a las denominadas placas de infección del hongo, cuya vista aumentada se observa en la fotografía de la derecha.',
                ],
              },
            ],
            image: diagnosis_mancha_image_7,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'El agente causal',
              },
              {
                type: 'body',
                value: [
                  'Conidias de la fase asexuada del hongo, P. herpotrichoides, creciendo en un medio de cultivo.',
                ],
              },
            ],
            image: diagnosis_mancha_image_8,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'Conidias individuales de P. herpotrichoides (Imagen 1).',
                  'Apotecios de la fase sexuada del hongo, O. yallundae, en rastrojo de trigo mantenido en cámara húmeda (Imagen 2).',
                ],
              },
            ],
            image: diagnosis_mancha_image_9,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Expresión de la enfermedad en siembras comerciales',
              },
            ],
            image: diagnosis_mancha_image_10,
          },
        ],
        prevention: [
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Época de siembra',
              },
              {
                type: 'subtitle',
                value:
                  'Cuando sea posible, en zonas de alto riesgo, atrasar la época de siembra. Esto permite escapar muy bien de la enfermedad.',
              },
              {
                type: 'body',
                value: [
                  'Las siembras más afectadas son las de otoño-invierno. Siembras establecidas desde mediados de Julio en adelante, no son afectadas por la mancha ocular.',
                ],
              },
            ],
            image: prevention_mancha_image_1,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Variedades tolerantes',
              },
              {
                type: 'subtitle',
                value:
                  'Lo ideal es sembrar una variedad resistente o tolerante a la enfermedad.',
              },
              {
                type: 'body',
                value: [
                  'Sin embargo, se desconoce el nivel de resistencia de las variedades de trigo en Chile, puesto que no se ha seleccionado para la enfermedad por ser ésta una patología relativamente nueva.',
                ],
              },
            ],
            image: prevention_mancha_image_2,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: '¡Muy importante!',
              },
            ],
            image: null,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Evitar la contaminación de los potreros',
              },
              {
                type: 'subtitle',
                value:
                  'La maquinaria e implementos agrícolas son los principales contaminantes de los suelos.',
              },
              {
                type: 'body',
                value: [
                  'Debe limpiar completamente ruedas de maquinarias y todos los implementos agrícolas, antes de ingresar a un potrero, especialmente si le prestan servicio de arriendo.',
                  'La mancha ocular se disemina de un potrero a otro y de un predio a otro, a través del rastrojo que se encuentra en el suelo y todo lo que está en contacto con éste (maquinaria, herramientas, implementos, animales, botas, etc.)',
                ],
              },
            ],
            image: prevention_mancha_image_3,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Manejo de rastrojos',
              },
              {
                type: 'subtitle',
                value: 'El rastrojo debe ser incorporado o destruido.',
              },
              {
                type: 'body',
                value: [
                  'El rastrojo es la primera fuente de infección, puesto que ahí sobrevive el hongo.',
                  'La siembra de trigo sobre rastrojo del mismo cultivo, se traducirá en una infección más temprana y de una mayor severidad.',
                ],
              },
            ],
            image: prevention_mancha_image_4,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Rotación de cultivos',
              },
              {
                type: 'subtitle',
                value:
                  'La rotación de cultivos ayuda a disminuir la severidad de la infección.',
              },
              {
                type: 'body',
                value: [
                  'La rotación de cultivos es muy importante para disminuir la población del hongo con lo cual se reduce significativamente el daño.',
                ],
              },
            ],
            image: prevention_mancha_image_5,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value:
                  'Controlar malezas gramíneas en forma eficiente todos los años',
              },
              {
                type: 'subtitle',
                value:
                  'Muchas malezas gramíneas son hospederos del hongo y lo mantienen y/o aumentan, a través de las temporadas en ausencia del cultivo.',
              },
              {
                type: 'body',
                value: [
                  'Las malezas gramíneas deben ser eficientemente eliminadas, en todas las temporadas.',
                ],
              },
            ],
            image: prevention_mancha_image_6,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Regular adecuadamente la densidad de siembra',
              },
              {
                type: 'subtitle',
                value:
                  'Una alta población de plantas genera competencia entre ella, reduce la ventilación, aumenta la humedad y produce cañas más débiles.',
              },
              {
                type: 'body',
                value: [
                  'Una población muy alta de plantas de trigo favorecerá la tendedura en casos de infección.',
                ],
              },
            ],
            image: prevention_mancha_image_7,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'No exceda la fertilización nitrogenada',
              },
              {
                type: 'subtitle',
                value:
                  'Una alta fertilización nitrogenada es contra producente.',
              },
              {
                type: 'body',
                value: [
                  'El exceso de nitrógeno retarda la lignificación y madurez de los tejidos, dejándolos más susceptibles a la tendedura.',
                  'Además, favorece una mayor masa vegetativa, aumentando la humedad relativa y predispone a un ataque más severo.',
                ],
              },
            ],
            image: prevention_mancha_image_8,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Disminuir la altura de las plantas',
              },
              {
                type: 'subtitle',
                value:
                  'Reducir la altura de las plantas con un regulador de crecimiento, evita o disminuye la tendedura.',
              },
              {
                type: 'body',
                value: [
                  'Las siembras densas y/o con altas dosis de fertilizantes nitrogenados, inducen plantas con tallos menos lignificados y más propensas a la tendedura.',
                  'Al disminuir la altura de las plantas, los tallos soportan en mejor forma el peso de éstas y se evita las tendeduras masivas.',
                ],
              },
            ],
            image: prevention_mancha_image_9,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Aplicar fungicidas en el momento oportuno',
              },
              {
                type: 'body',
                value: [
                  'La aplicación foliar de fungicidas tiene su momento oportuno. Y esto es particularmente aplicable a la mancha ocular. En el caso de esta enfermedad, el tratamiento es uno sólo y de tipo preventivo, es decir se aplica en ausencia de síntomas, y a inicio de encañado como máximo.',
                  'Aplicar en estados de desarrollo inadecuados, o con productos de baja eficacia, sólo se traducirá en un mal resultado económico.',
                  'Emplee sólo fungicidas reconocidamente eficaces. Elija aquellos que posean antecedentes de indicadores de infección (% daño foliar, % plantas afectadas, notas de severidad, etc.). Estos indicadores son mucho más importantes que los de rendimiento o calidad de grano.',
                ],
              },
            ],
            image: prevention_mancha_image_10,
          },
        ],
      },
    },
    {
      title: 'Pudrición radical',
      description:
        'La Pudrición Radical, o Mal del Pié del trigo (takeall en Inglés), es una de las enfermedades más destructivas que afectan a este cultivo en todo el...',
      dialogTitle: 'Pudrición radical o mal del pié del trigo',
      imageCard: pudricion_radical,
      pdf: pudricion_radicalPDF,
      video: null,
      moreInfo: {
        control: [
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value:
                  '¡No existen alternativas de control eficaces para esta enfermedad!',
              },
              {
                type: 'subtitle',
                value:
                  'Solo hay medidas preventivas (ver sección de Prevención) y paliativas',
              },
            ],
            image: control_pudricion_image_1,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Fertilización',
              },
              {
                type: 'subtitle',
                value:
                  'La aplicación de nitrógeno en forma parcializada contribuye eficazmente a reducir el daño, al tener las raíces una mejor disponibilidad del nutriente.',
              },
              {
                type: 'body',
                value: [
                  'Esto, les ayuda a reponer más fácilmente las raíces dañadas.',
                ],
              },
            ],
            image: control_pudricion_image_2,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Desinfección de semilla',
              },
              {
                type: 'subtitle',
                value:
                  'Algunos desinfectantes de semilla logran retardar el inicio de la infección. Sin embargo, no controlan la enfermedad, sólo disminuyen las pérdidas en magnitudes variables y, a veces, indetectable.',
              },
              {
                type: 'body',
                value: [
                  'Los desinfectantes de semilla que han logrado retardar la infección de mal del pié: fluquinconazole (Galmano), triadimenol (Baytan 150 FS), triticonazole (Real 200 SC). Se requieren dosis altas para lograr retardar la infección.',
                ],
              },
            ],
            image: control_pudricion_image_3,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Aplicación de fungicidas foliares',
              },
              {
                type: 'subtitle',
                value:
                  'La aplicación foliar temprana de fungicida ha logrado retardar igualmente el inicio de la infección de mal del pié. Sin embargo, no provee control de la enfermedad, sólo disminuye las pérdidas en magnitudes variables.',
              },
              {
                type: 'body',
                value: [
                  'El fungicida azoxystrobin (PrioriXtra, Custodia 320 SC, Impulso 125 EW), aplicado a 3-4 hojas y luego a inicio de encañado, logra retardar el inicio de la infección de mal del pié.',
                  'El investigador O. Andrade sólo ha evaluado el efecto del fungicida Priori 25 SC, como fungicida foliar en mal del pié.',
                ],
              },
            ],
            image: control_pudricion_image_4,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Variedades resistentes',
              },
              {
                type: 'subtitle',
                value:
                  'No existen variedades resistentes al mal del pié. Todas las variedades son susceptibles.',
              },
            ],
            image: control_pudricion_image_5,
          },
        ],
        diagnosis: [
          {
            type: 'main',
            title: 'Organismo causal: ',
            causeTitle: 'Hongo',
            subtitle: 'Nombre del hongo: ',
            causeSubtitle: 'Gaeumannomyces graminis var. tritici',
            image: diagnosis_pudricion_main,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Síntomas',
              },
              {
                type: 'body',
                value: [
                  'Manchones de plantas con amarillez y menor desarrollo (Imagen 1).',
                  'Clorosis (amarillez) generalizada, muy acentuada en las hojas basales (Imagen 2).',
                ],
              },
            ],
            image: diagnosis_pudricion_image_1,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'En estados de desarrollo más avanzado, manchones de plantas con severa amarillez, escaso desarrollo y ralos.',
                ],
              },
            ],
            image: diagnosis_pudricion_image_2,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'Amarillez de hojas basales con necrosis apical (muerte de tejido en las puntas de las hojas).',
                ],
              },
            ],
            image: diagnosis_pudricion_image_3,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'Ennegrecimiento parcial o total de raicillas y raíces en primeros estados de desarrollo.',
                ],
              },
            ],
            image: diagnosis_pudricion_image_4,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'Ennegrecimiento parcial o total de raicillas, raíces y cuellos, en estados de desarrollo avanzado.',
                ],
              },
            ],
            image: diagnosis_pudricion_image_5,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'Espigas blancas y vanas producto del daño en las raíces.',
                ],
              },
            ],
            image: diagnosis_pudricion_image_6,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'body',
                value: [
                  'Plantas bajas, con espigas pequeñas, vanas y negras, hacia la madurez.',
                ],
              },
            ],
            image: diagnosis_pudricion_image_7,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'El agente causal',
              },
              {
                type: 'body',
                value: [
                  'Espigas negras (izq.), colonizadas por hongos saprófitos y eventualmente también, espigas rojas (der), colonizadas por levaduras.',
                ],
              },
            ],
            image: diagnosis_pudricion_image_8,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'El agente causal',
              },
            ],
            image: diagnosis_pudricion_image_9,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Expresión de la enfermedad en siembras comerciales',
              },
            ],
            image: diagnosis_pudricion_image_10,
          },
        ],
        prevention: [
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Evitar la contaminación de los potreros',
              },
              {
                type: 'subtitle',
                value:
                  'La maquinaria e implementos agrícolas son los principales contaminantes de los suelos.',
              },
              {
                type: 'body',
                value: [
                  'Limpiar completamente ruedas de maquinarias y todos los implementos agrícolas, antes de ingresar a un potrero y a la salida de éste, especialmente si le prestan servicio de arriendo.',
                  'También se produce contaminación por animales provenientes de otros campos, herramientas, calzado, ruedas de vehículos y carros, etc.',
                ],
              },
            ],
            image: prevention_pudricion_image_1,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Rotación de cultivos',
              },
              {
                type: 'subtitle',
                value:
                  'La rotación de cultivos es la más eficaz de las medidas de prevención del mal del pié.',
              },
              {
                type: 'body',
                value: [
                  'Disminuye la población del hongo en el suelo con lo cual se reduce significativamente el daño.',
                ],
              },
            ],
            image: prevention_pudricion_image_2,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Manejo de rastrojos',
              },
              {
                type: 'subtitle',
                value: 'El rastrojo debe ser incorporado profundo.',
              },
              {
                type: 'body',
                value: [
                  'El rastrojo es la primera fuente de infección, puesto que ahí sobrevive el hongo.',
                  'La quema del rastrojo tiene un bajo efecto preventivo.',
                ],
              },
            ],
            image: prevention_pudricion_image_3,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Época de siembra',
              },
              {
                type: 'subtitle',
                value:
                  'Cuando sea posible, en potreros con historia de mal del pié, atrasar la época de siembra. Permite disminuir significativamente el riesgo de infección, al escapar de la alta humedad del suelo.',
              },
            ],
            image: prevention_pudricion_image_4,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value:
                  'Controlar malezas gramíneas en forma eficiente, todos los años',
              },
              {
                type: 'subtitle',
                value:
                  'Muchas malezas gramíneas son hospederos del hongo y lo mantienen y/o aumentan a través de las temporadas, en ausencia del cultivo.',
              },
            ],
            image: prevention_pudricion_image_5,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Drenaje del suelo',
              },
              {
                type: 'subtitle',
                value:
                  'La construcción de drenes en potreros con problemas de humedad contribuye en forma importante a disminuir la severidad de la infección.',
              },
            ],
            image: prevention_pudricion_image_6,
          },
          {
            type: 'content',
            texts: [
              {
                type: 'title',
                value: 'Variedades resistentes',
              },
              {
                type: 'subtitle',
                value:
                  'No existen variedades resistentes al mal del pié Todas son susceptibles.',
              },
            ],
            image: prevention_pudricion_image_7,
          },
        ],
      },
    },
  ],
};

export default trigo;
