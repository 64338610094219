import React from 'react';
import {useLocation} from 'react-router-dom';
import {Grid, Box, Tab, Alert} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';

// Sections
import EntryImage from './EntryImage';

// My components
import LearningCard from './LearningCard';

// Learning items
import learningSections from './Sections';

export function Learning() {
  let location = useLocation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [value, setValue] = React.useState('Trigo');

  React.useEffect(() => {
    // Google Analytics
    if (location.state?.section) {
      setValue(location.state.section);
    }
  }, [location]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid justifyContent="center" container>
      <Grid item xs={12} sm={12}>
        <EntryImage />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TabContext value={value}>
          <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
            <TabList
              onChange={handleChange}
              variant={matches ? 'scrollable' : 'fullWidth'}
              scrollButtons={matches}
              allowScrollButtonsMobile
              aria-label="lab API tabs example">
              <Tab label="Trigo" value="Trigo" sx={{fontSize: 'medium'}} />
              <Tab label="Cebada" value="Cebada" sx={{fontSize: 'medium'}} />
              <Tab label="Raps" value="Raps" sx={{fontSize: 'medium'}} />
              <Tab label="Papa" value="Papa" sx={{fontSize: 'medium'}} />
              <Tab label="Avena" value="Avena" sx={{fontSize: 'medium'}} />
              <Tab label="Lupino" value="Lupino" sx={{fontSize: 'medium'}} />
            </TabList>
          </Box>
        </TabContext>
      </Grid>
      <Grid item xs={12} sm={12} sx={{margin: '4rem'}}>
        {learningSections.map((section, index) => (
          <React.Fragment key={index}>
            {section.title === value && section.cards ? (
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Grid container justifyContent="center" spacing={2}>
                    {section.cards.length > 0 ? (
                      section.cards.map((card, index) => (
                        <Grid item key={index}>
                          <LearningCard
                            title={card.title}
                            dialogTitle={card.dialogTitle}
                            desciption={card.description}
                            imageCard={card.imageCard}
                            pdf={card.pdf}
                            video={card.video}
                            moreInfo={card.moreInfo}
                          />
                        </Grid>
                      ))
                    ) : (
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        key={index}>
                        <Alert variant="outlined" severity="info">
                          Próximamente habrá información sobre{' '}
                          {`${section.title}`}
                        </Alert>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
          </React.Fragment>
        ))}
      </Grid>
    </Grid>
  );
}

export default Learning;
