// Sections
import avena from './Sections/avena';
import trigo from './Sections/trigo';
import cebada from './Sections/cebada';
import raps from './Sections/raps';
import papa from './Sections/papa';
import lupino from './Sections/lupino';

const learningSections = [avena, trigo, cebada, raps, papa, lupino];

export default learningSections;
