import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TeamDialogResume(props) {
  const {open, resume, handleClose} = props;
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={'xl'}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description">
      <DialogTitle>{resume ? resume.name : ''}</DialogTitle>
      <DialogContent style={{height: '60rem'}}>
        <iframe
          src={resume?.cv}
          style={{width: '100%', height: '100%', border: 'none'}}
          title="PDF Document"
          width="100%"
          height="100%"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color={'inherit'}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
