import React from 'react';
import {Grid, Typography, Paper} from '@mui/material';

export function OurWork() {
  return (
    <Paper
      variant="outlined"
      sx={{
        width: '100%',
        height: '100%',
        borderLeftColor: 'primary.main',
        backgroundColor: 'grey.50',
        borderLeftWidth: 4,
        borderRightWidth: -4,
      }}>
      <Grid container spacing={2} sx={{padding: '3rem'}}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h5" align="left" color="primary">
            Nuestras actividades
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} sx={{marginTop: '1rem'}}>
          <Typography variant="body1" align="left">
            Nuestras principales actividades están relacionadas con el
            desarrollo de agroquímicos, a través de la evaluación de eficacia de
            fungicidas, herbicidas e insecticidas.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography variant="body1" align="left">
            Además, realizamos investigación en estrategias de control de
            enfermedades, evaluación de variedades comerciales y líneas
            promisorias de diversos cultivos frente a las principales
            enfermedades de la zona sur, evaluación de fertilizantes,
            bioestimulantes y agentes de control biológico, entre otras.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography variant="body1" align="left">
            Todas las actividades son ejecutadas exclusivamente por
            especialistas de larga y reconocida trayectoria en investigación y
            en las diversas disciplinas agronómicas.
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default OurWork;
