import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import {Divider, Grid, Typography, Link} from '@mui/material';
import {Lightbox} from 'react-modal-image';

import logo from '../../resources/logo.png';
import map from '../../resources/mapa.jpg';

export function FixedFooter() {
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);

  return (
    <Grid
      container
      spacing={0}
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: '15rem',
        width: '100%',
        backgroundColor: 'text.secondary',
        marginTop: '1.5rem',
        textAlign: matches ? 'center' : 'left',
      }}>
      <Grid item xs={12} sx={{width: '100%', height: '100%'}}>
        <Grid
          container
          spacing={0}
          sx={{width: '100%', height: '100%'}}
          alignItems="center"
          alignContent="center">
          <Grid item xs={1} />
          <Grid item xs={12} sm={2}>
            <img alt="logo" src={logo} />
          </Grid>

          <Grid item xs={0} sm={1} sx={{width: '100%', height: '100%'}} />
          <Grid item xs={12} sm={3}>
            <Grid
              container
              spacing={0}
              alignItems="center"
              sx={{width: '100%', height: '100%'}}
              justifyContent="flex-start">
              <Grid item xs={12} sm={5}>
                <Link
                  variant={'body2'}
                  color="secondary"
                  gutterBottom
                  component={Typography}
                  onClick={() => navigate('/services')}>
                  Servicios
                </Link>
                <Link
                  variant={'body2'}
                  color="secondary"
                  gutterBottom
                  component={Typography}
                  onClick={() => navigate('/us')}>
                  Nosotros
                </Link>
                <Link
                  variant={'body2'}
                  color="secondary"
                  gutterBottom
                  component={Typography}
                  onClick={() => navigate('/learning')}>
                  Aprende con nosotros
                </Link>
                <Link
                  variant={'body2'}
                  color="secondary"
                  gutterBottom
                  component={Typography}
                  onClick={() => {
                    window.open('https://app.eeagrodelsur.cl/ingresar');
                  }}>
                  Empresas
                </Link>
              </Grid>
              <Grid
                item
                xs={0}
                sm={3}
                sx={{height: '10rem', textAlign: 'center'}}>
                <Divider
                  orientation={'vertical'}
                  sx={{
                    float: 'right',
                    backgroundColor: 'secondary.main',
                    display: {xs: 'none', sm: 'block', lg: 'block'},
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container spacing={0}>
              <Grid item xs={12} sx={{padding: '0.4rem'}}>
                <Typography variant={'caption'} color="secondary">
                  Dirección
                </Typography>
                <Link
                  variant={'h6'}
                  color="secondary"
                  component={Typography}
                  onClick={() => setOpen(true)}>
                  Km. 1 Ruta 5 Sur (entrada sur) a Perquenco
                </Link>
                <Typography variant={'subtitle1'} color="secondary">
                  Región de La Araucanía.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2} />
        </Grid>
      </Grid>
      {open && (
        <Lightbox
          medium={map}
          large={map}
          alt="Hello World!"
          onClose={() => setOpen(false)}
        />
      )}
    </Grid>
  );
}

export default FixedFooter;
