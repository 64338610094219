import React from 'react';
import {createRoot} from 'react-dom/client';
import {HashRouter as Router, Routes, Route} from 'react-router-dom';
import {store} from './app/store';
import {Provider} from 'react-redux';
import * as serviceWorker from './serviceWorker';

import './index.css';
import {ThemeProvider} from '@mui/material/styles';
import theme from './styleSheet';

import FixedAppBar from './features/Layout/AppBar';
import FixedFooter from './features/Layout/FixedFooter';
import ScrollToTop from './features/Layout/ScrollToTop';

import Home from './features/Home/Home';
import Us from './features/Us/Us';
import Services from './features/Services/Services';
import Learning from './features/Learning/Learning';
import News from './features/News/News';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <div style={{maxWidth: '1900px'}}>
          <Router>
            <ScrollToTop />
            <FixedAppBar />
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/us" element={<Us />} />
              <Route exact path="/services" element={<Services />} />
              <Route exact path="/learning" element={<Learning />} />
              <Route exact path="/news" element={<News />} />
            </Routes>
            <FixedFooter />
            <div style={{marginBottom: '0.5rem'}} />
          </Router>
        </div>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
