import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';
import FlagIcon from '@mui/icons-material/Flag';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {Divider} from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TeamDialog(props) {
  const {open, teamMember, handleClose} = props;
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={'sm'}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description">
      <DialogTitle>{teamMember ? teamMember.name : ''}</DialogTitle>
      <DialogContent>
        <List sx={{width: '100%', bgcolor: 'background.paper'}}>
          {teamMember && teamMember.position ? (
            <React.Fragment>
              <ListItem>
                <ListItemAvatar>
                  <WorkIcon />
                </ListItemAvatar>
                <ListItemText primary="Cargo" secondary={teamMember.position} />
              </ListItem>
              <Divider />
            </React.Fragment>
          ) : null}
          {teamMember && teamMember.degree ? (
            <React.Fragment>
              <ListItem>
                <ListItemAvatar>
                  <SchoolIcon />
                </ListItemAvatar>
                <ListItemText
                  primary="Profesión"
                  secondary={teamMember.degree}
                />
              </ListItem>
              <Divider />
            </React.Fragment>
          ) : null}
          {teamMember && teamMember.nacionality ? (
            <React.Fragment>
              <ListItem>
                <ListItemAvatar>
                  <FlagIcon />
                </ListItemAvatar>
                <ListItemText
                  primary="Nacionalidad"
                  secondary={teamMember.nacionality}
                />
              </ListItem>
              <Divider />
            </React.Fragment>
          ) : null}
          {teamMember && teamMember.phone ? (
            <React.Fragment>
              <ListItem>
                <ListItemAvatar>
                  <LocalPhoneIcon />
                </ListItemAvatar>
                <ListItemText primary="Teléfono" secondary={teamMember.phone} />
              </ListItem>
              <Divider />
            </React.Fragment>
          ) : null}
          {teamMember && teamMember.mail ? (
            <React.Fragment>
              <ListItem>
                <ListItemAvatar>
                  <EmailIcon />
                </ListItemAvatar>
                <ListItemText primary="Correo" secondary={teamMember.mail} />
              </ListItem>{' '}
              <Divider />
            </React.Fragment>
          ) : null}
          {teamMember && teamMember.address ? (
            <React.Fragment>
              <ListItem>
                <ListItemAvatar>
                  <LocationOnIcon />
                </ListItemAvatar>
                <ListItemText
                  primary="Dirección laboral"
                  secondary={teamMember.address}
                />
              </ListItem>
              <Divider />
            </React.Fragment>
          ) : null}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color={'inherit'}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
