// Images
import orlando from '../../resources/us/orlando_andrade.jpg';
import cesar from '../../resources/us/cesar_morales_2.jpg';
import jordan from '../../resources/us/jordan_ayalef.jpg';

// CV

import orlando_cv from '../../resources/us/orlando_andrade_cv.pdf';
import cesar_cv from '../../resources/us/cesar_morales_cv.pdf';
import jordan_cv from '../../resources/us/jordan_ayalef_cv.pdf';

const teamInfo = [
  {
    name: 'Orlando Armando Andrade Vilaró',
    position: 'Gerente Técnico e Investigador',
    degree: 'Ingeniero Agrónomo, Ph.D., Fitopatólogo',
    nacionality: 'Chileno',
    phone: '+56 9 90857875',
    mail: 'oandrade54@gmail.com',
    address:
      'Estación Experimental Agro del Sur \n' +
      'Km 1 camino ruta 5 Sur a Perquenco \n' +
      'Perquenco, Región de La Araucanía.\n',
    image: orlando,
    cv: orlando_cv,
  },
  {
    name: 'César Alexis Morales Cayuleo',
    position: 'Encargado Actividades de Campo',
    degree: 'Ingeniero Agrónomo',
    nacionality: 'Chileno',
    phone: '+56 9 84210991',
    mail: 'calexis0056@gmail.com',
    address: null,
    image: cesar,
    cv: cesar_cv,
  },
  {
    name: 'Jordan Antonio Ayalef Necul',
    position: 'Asistente Actividades de Campo',
    degree: 'Ingeniero Agrónomo',
    nacionality: 'Chileno',
    phone: '+56 9 67010891',
    mail: 'calexis0056@gmail.com',
    address: null,
    image: jordan,
    cv: jordan_cv,
  },
];

export default teamInfo;
