import React from 'react';
import {Grid, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import ReactPlayer from 'react-player';

import Services from './Sections/Services';
import OurWork from './Sections/OurWork';
// import News from './Sections/News';
import AboutUs from './Sections/AboutUs';
import Learning from './Sections/Learning';

const styles = {
  marginSection: {
    marginTop: '2rem',
  },
  initialMargin: {
    marginTop: '1rem',
  },
};

export function Home() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid
        item
        xs={12}
        sm={12}
        style={{
          height: matches ? '50vw' : '32vw',
          overflow: 'hidden',
          position: 'relative',
          //bottom: matches ? '0hv' : '0vh',
        }}>
        <div
          style={{
            width: '100%',
            height: 'auto',
            position: 'absolute',
            bottom: matches ? '0vh' : '-20vh',
          }}>
          <ReactPlayer
            url={
              'https://storage.googleapis.com/agro-del-sur/video-agro-del-sur.mp4'
            }
            muted={true}
            playing={true}
            loop={true}
            //style={{top: matches ? '0vh' : '-40vh'}}
            width="100%"
            height={'auto'}
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={12} sx={styles.initialMargin}>
        <Typography variant="h3" align="center" color="primary">
          Servicios
        </Typography>
      </Grid>
      <Grid item xs={8} sm={8} sx={styles.marginSection}>
        <Services />
      </Grid>
      <Grid item xs={8} sm={8} sx={styles.marginSection}>
        <OurWork />
      </Grid>
      <Grid item xs={12} sm={12} sx={styles.marginSection}>
        <AboutUs />
      </Grid>
      <Grid item xs={12} sm={12} sx={styles.marginSection}>
        <Typography variant="h3" align="center" color="primary">
          Conocimiento experto al alcance
        </Typography>
      </Grid>
      <Grid item xs={8} sm={8} sx={styles.marginSection}>
        <Learning />
      </Grid>
      {/*<Grid item xs={12} sm={12} sx={styles.marginSection}>*/}
      {/*  <Typography variant="h3" align="center" color="primary">*/}
      {/*    ¿Qué hay de nuevo?*/}
      {/*  </Typography>*/}
      {/*</Grid>*/}
      {/*<Grid item xs={8} sm={8} sx={styles.marginSection}>*/}
      {/*  <News />*/}
      {/*</Grid>*/}
    </Grid>
  );
}

export default Home;
