import React from 'react';
import {Grid, Typography} from '@mui/material';

import currentNews from './currentNews';

const styles = {
  defaultSeparation: {
    marginTop: '5rem',
  },
  newsSeparation: {
    marginTop: '4rem',
    position: 'relative',
  },
  specialNews: {
    border: '2px solid green',
    padding: '3rem',
  },
  zeroPadding: {
    paddingTop: '0px!important',
  },
  featureText: {
    position: 'absolute',
    top: -50,
    left: -20,
    fontWeight: 'bold',
    backgroundColor: '#FFFFFF',
    height: '1rem',
    width: '18rem',
    padding: '1rem',
  },
  featureTextInitial: {
    position: 'absolute',
    top: -50,
    left: -20,
    fontWeight: 'bold',
    backgroundColor: '#FFFFFF',
    height: '3rem',
    padding: '1rem',
  },
};

export function NewsList() {
  const [hoverNews, setHoverNews] = React.useState(null);

  return (
    <Grid justifyContent="center" container>
      {currentNews.map((news, index) => (
        <Grid item xs={8} sm={8} sx={styles.newsSeparation} key={index}>
          <Grid
            justifyContent="center"
            container
            spacing={2}
            sx={index === 0 ? styles.specialNews : {}}>
            <Grid item xs={12} sm={3} xl={3} sx={styles.zeroPadding}>
              <img
                alt={`news ${index}`}
                src={news.image}
                style={{width: 'auto', height: '100%', maxWidth: '100%'}}
              />
            </Grid>
            <Grid item xs={12} sm={9} xl={9} sx={styles.zeroPadding}>
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Typography variant={'body1'} color={'primary'}>
                    Noticia
                  </Typography>
                  <Typography variant={'caption'} color={'text.secondary'}>
                    {news.date}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  onMouseEnter={() => setHoverNews(index)}
                  onMouseLeave={() => setHoverNews(null)}
                  onClick={() => console.log('click')}>
                  <Typography
                    variant={'h6'}
                    sx={{
                      textDecoration:
                        index === hoverNews ? 'underline' : 'none',
                    }}>
                    {news.title}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant={'body2'}>Por Orlando Andrade</Typography>
                </Grid>
              </Grid>
            </Grid>
            {index === 0 && (
              <React.Fragment>
                <div style={styles.featureTextInitial}></div>
                <div style={styles.featureText}>
                  <Typography variant={'h4'} color={'primary'}>
                    Historia destacada
                  </Typography>
                </div>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

export default NewsList;
