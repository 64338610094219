import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {useNavigate} from 'react-router-dom';
import logo from '../../resources/logo.png';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useEffect, useState} from 'react';

export default function FixedAppBar() {
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [scroll, setScroll] = useState(0);
  const [onHover, setOnHover] = useState(false);

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = event => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
    return function cleanUp() {
      window.removeEventListener('scroll', handleScroll);
    };
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {passive: true});
  });

  const onHoverSx = {
    maxWidth: '100%',
    textAlign: 'center',
    background: `rgba(255, 255, 255, ${scroll / 400 < 1 ? scroll / 400 : 1})`,
    '&:hover': {
      background: 'rgba(255, 255, 255,1)',
    },
    transition: 'background .25s linear',
  };

  const notOnHoverSx = {
    maxWidth: '100%',
    textAlign: 'center',
    background: `rgba(255, 255, 255, ${scroll / 400 < 1 ? scroll / 400 : 1})`,
  };

  return (
    <Box sx={{flexGrow: 1, display: 'hidden'}}>
      <AppBar
        elevation={scroll === 0 && !onHover ? 0 : 4}
        onMouseEnter={() => {
          setOnHover(true);
        }}
        onMouseLeave={() => {
          setOnHover(false);
        }}
        position="fixed"
        color="default"
        sx={onHover ? onHoverSx : notOnHoverSx}>
        <Toolbar disableGutters>
          <IconButton
            sx={{
              flexGrow: 0.1,
              flex: 'left',
            }}
            onClick={() => {
              navigate('/');
            }}>
            <img alt="logo" src={logo} style={{width: matches ? 60 : 80}} />
          </IconButton>

          <Box
            sx={{
              flexGrow: 1,
              display: {xs: 'flex', md: 'none'},
              justifyContent: 'right',
              marginRight: '1rem',
            }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit">
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: {xs: 'block', md: 'none'},
              }}>
              <MenuItem>
                <Button
                  color="inherit"
                  onClick={() => {
                    navigate('/');
                  }}>
                  Inicio
                </Button>
              </MenuItem>

              <MenuItem>
                <Button
                  color="inherit"
                  onClick={() => {
                    navigate('/services');
                  }}>
                  Servicios
                </Button>
              </MenuItem>

              <MenuItem>
                <Button
                  color="inherit"
                  onClick={() => {
                    navigate('/us');
                  }}>
                  Nosotros
                </Button>
              </MenuItem>

              <MenuItem>
                <Button
                  color="inherit"
                  onClick={() => {
                    navigate('/learning');
                  }}>
                  Aprende con nosotros
                </Button>
              </MenuItem>

              <MenuItem>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{marginLeft: '0.5rem'}}
                  onClick={() => {
                    window.open('https://app.eeagrodelsur.cl/ingresar');
                  }}>
                  Acceso Empresas
                </Button>
              </MenuItem>
            </Menu>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: {xs: 'none', md: 'flex'},
              justifyContent: 'right',
              marginRight: '0.5rem',
            }}>
            <Button
              color="inherit"
              onClick={() => {
                navigate('/');
              }}>
              Inicio
            </Button>
            <Button
              color="inherit"
              onClick={() => {
                navigate('/services');
              }}>
              Servicios
            </Button>
            {/*<Button*/}
            {/*  color="inherit"*/}
            {/*  onClick={() => {*/}
            {/*    navigate('/news');*/}
            {/*  }}>*/}
            {/*  Noticias*/}
            {/*</Button>*/}
            <Button
              color="inherit"
              onClick={() => {
                navigate('/us');
              }}>
              Nosotros
            </Button>
            <Button
              color="inherit"
              onClick={() => {
                navigate('/learning');
              }}>
              Aprende con nosotros
            </Button>
            <Button
              color="primary"
              variant="contained"
              sx={{marginLeft: '0.5rem'}}
              onClick={() => {
                window.open('https://app.eeagrodelsur.cl/ingresar');
              }}>
              Acceso Empresas
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
